import {useRecoilState} from "recoil";
import {csatViewedListState} from "../recoil/atoms/csatViewedListState";
import {csatShowState} from "../recoil/atoms/csatShowState";

export default function useCsat(csatName: string) {
  const [csatViewedList, setCsatViewedList] = useRecoilState(csatViewedListState);
  const [csatShow, setCsatShow] = useRecoilState(csatShowState);

  const addAnsweredCsat = (name: string) => {
    setCsatViewedList([
      ...csatViewedList,
      name
    ])
  }


  const handleShowCsat = (name: string) => {
    setCsatShow(name);
  }

  return {
    addAnsweredCsat,
    isCsatVisible: !csatViewedList.includes(csatName) && csatName !== '' && csatShow !== '' && csatName === csatShow,
    handleShowCsat
  }
}
