import React, {FC, useCallback, useEffect, useState} from 'react';
import Expand01Icon from '@untitled-ui/icons-react/build/esm/Expand01';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import IconButton from '@mui/material/IconButton';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import SvgIcon from '@mui/material/SvgIcon';
import Typography from '@mui/material/Typography';
import {default as MuiModal} from "@mui/material/Modal";
import Box from "@mui/material/Box";
import {ModalProps} from "@mui/material/Modal/Modal";
import useMediaQuery from "@mui/material/useMediaQuery";
import {Theme} from "@mui/material/styles/createTheme";
import {Grid} from "@mui/material";

interface Props extends ModalProps {
  defaultExpanded?: boolean
  title?: string
  disableExpand?: boolean
  disableHeader?: boolean
  backgroundImage?: string
  maxWidth?: number | string
}

export const Modal: FC<Props> = (props) => {
  const {
    defaultExpanded = false,
    disableHeader = false,
    title = '',
    children,
    disableExpand,
    backgroundImage,
    maxWidth,
    ...rest
  } = props;
  const [expanded, setExpanded] = useState(defaultExpanded)
  const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'));

  useEffect(() => {
    if (!expanded && !smUp) {
      setExpanded(true)
    } else {
      setExpanded(false)
    }
  }, [smUp])

  const handleExpanded = useCallback(() => setExpanded(prevState => !prevState), [])
  const handleClose = useCallback((e: object) => rest?.onClose && rest?.onClose(e, 'escapeKeyDown'), [])

  return (
    <MuiModal
      {...rest}
    >
      <Box
        component={Paper}
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          bgColor: 'transparent',
          border: 'none',
          boxShadow: 24,
          width: expanded ? '100%' : 'auto',
          height: expanded ? '100%' : 'auto',
          minWidth: expanded ? '100%' : maxWidth,
          p: 0,
          display: 'flex',
          flexDirection: 'column',
          margin: expanded ? 0 : 3,
          maxWidth: expanded ? '100%' : maxWidth,
          mx: 'auto',
          outline: 'none',
          backgroundImage
        }}
      >
        {!disableHeader && (
          <Box
            sx={{
              d: "block",
              w: "100%"
            }}
          >
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              sx={{
                px: 2,
                py: 1,
                maxWidth: expanded ? '100%' : maxWidth,
              }}
            >
              <Grid container>
                <Grid md={8}>
                  <Typography
                    sx={{flexGrow: 1}}
                    variant="h6"
                  >
                    {title}
                  </Typography>
                </Grid>
                <Grid md={2}>
                  {(smUp && !disableExpand) && (
                    <IconButton
                      onClick={handleExpanded}
                    >
                      <SvgIcon>
                        <Expand01Icon/>
                      </SvgIcon>
                    </IconButton>
                  )}
                </Grid>
                <Grid md={2}>
                  <IconButton
                    onClick={handleClose}
                    autoFocus
                  >
                    <SvgIcon>
                      <XIcon/>
                    </SvgIcon>
                  </IconButton>
                </Grid>
              </Grid>
            </Stack>
          </Box>
        )}
        {children}
      </Box>
    </MuiModal>
  );
}
