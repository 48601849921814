import {ImageQualityReportScore} from "seb-graph-api-types/generated";
import {Theme} from "@mui/material/styles/createTheme";

const colors = (index: ImageQualityReportScore) => (theme: Theme) => ({
  GOOD: theme.palette.success.main,
  ACCEPTABLE: theme.palette.warning.main,
  BAD: theme.palette.error.main
})[index]


export default colors;