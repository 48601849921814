import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function ObterListagemGrauParentesco() {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      listGrauParentescoCRM
    },
    error,
    errors
  } = await client.query({
    query: gql`
    query ListGrauParentescoCRM {
      listGrauParentescoCRM {
        valor
        label
      }
    }
    `,
    variables: {
    }
  })
  if (error) {
    alert("Erro");
  }

  dispatchGraphError(errors);

  return listGrauParentescoCRM;
}