import React from 'react';
import {Alert} from "react-bootstrap";
import WarningIcon from '@mui/icons-material/Warning';

function AlertBloqueio(
  {
    title = "Atenção",
    mensagemBloqueio = "Esta tela está em manutenção.\nRetornaremos em breve."
  }
) {
  return (
    <div style={{
      margin: "0 auto",
      maxWidth: "500px",
    }}>
      <Alert className="text-center" variant="light">
        <h3 className="text-center text-uppercase text-warning">
          <WarningIcon/> <span className="text-danger">{title}</span> <WarningIcon/>
        </h3>
        <h4>
          {mensagemBloqueio}
        </h4>
      </Alert>
    </div>
  );
}

export default AlertBloqueio;