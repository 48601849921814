import React from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import ModalFinanceiroFrank from "../../components/ModalFinanceiroFrank/ModalFinanceiroFrank";

function ManutencaoFinanceiraFrank(props: { [x: string]: any; Matricula: any; }) {
  const {
    Matricula,
    ...rest
  } = props;
  return (
    <ModalFinanceiroFrank
      {...rest}
      matricula={Matricula}
    />
  );
}

export default withUnidadeMatricula(ManutencaoFinanceiraFrank, "Manutenção Financeira");