import React from 'react';
import { Alert, AlertTitle } from '@mui/material';
import { Error as ErrorIcon } from '@mui/icons-material';

import './styles.css';

const ErrorMessage = ({ message }) => {
    return (
      <div className="error-message-container">
        <ErrorIcon className="error-icon" />
        <h1 className="error-title">Ops! Ocorreu um Erro!</h1>
        <Alert severity="error">
          <AlertTitle className="error-message">{message}</AlertTitle>
        </Alert>
      </div>
    );
  };
  
  export default ErrorMessage;