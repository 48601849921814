import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import {Alert, AlertTitle} from "@mui/material";
import React, {FC} from "react";
import {BlobProperties, ImageQualityReportScore} from "seb-graph-api-types/generated";
import ImageQualityReportList from "./ImageQualityReportList";


const FacialFoto: FC<{
  title?: string
  url?: any
  blobProps?: BlobProperties
  finalScore?: ImageQualityReportScore
  onClick?: () => void
  disabled?: boolean
  loading?: boolean
  width?: string | number
  height?: string | number
  placeholder?: string
  icon?: any
  warning?: string | null
}> = (
  {
    url,
    finalScore,
    title ,
    onClick,
    disabled = false,
    placeholder,
    blobProps,
    icon,
    loading,
    width,
    height,
    warning = null,
  }
) => {
  return (
    <>
      <Stack
        spacing={0}
        flexDirection="row"
        justifyContent="center"
        sx={{
          mb: 3
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
        >
          <Stack
            spacing={2}
          >
            {title && (
              <Typography
                variant="h6"
                sx={{
                  textAlign: "center"
                }}
              >
                {title}
              </Typography>
            )}
            
            <ProfilePicture
              url={url}
              color={finalScore === "BAD" ? "red" : undefined}
              disableHover={disabled}
              placeholder={placeholder}
              onClick={disabled ? () => {} : onClick}
              height={height}
              icon={icon}
              width={width}
              loading={loading}
            />
          </Stack>
        </Stack>

        {(!loading && blobProps?.metadata?.analysisDecoded?.imageQualityReport) &&
          <ImageQualityReportList
            imageQualityReport={blobProps?.metadata?.analysisDecoded?.imageQualityReport}
            sx={{
              mt: title ? 5 : 0,
            }}
          />
        }
      </Stack>

      {finalScore === "BAD" && !disabled && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            maxWidth: 200,
            ml: 'auto',
            mr: 'auto'
          }}
        >
          <AlertTitle>Atenção</AlertTitle>
          Tirar nova foto!
        </Alert>
      )}
      {warning && (
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            maxWidth: 200,
            ml: 'auto',
            mr: 'auto'
          }}
        >
          {warning}
        </Alert>
      )}
    </>
  )
}

export default FacialFoto;
