import React, { Component } from "react";
import { Movimentacoes_responsavel } from "./Movimentacoes_responsavel";
import Responsavel_rateio from "./Responsavel_rateio";
import * as _movimentacoesService from '../../service/movimentacoes-service';
import * as Message from "../../components/shared/Message";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import csatList from "../../utils/csatList";

class TrocaResponsavelFinanceiro extends Component {
    constructor(props) {
        super(props);
        this.state = this.getState()
    }

    getState() {
        return {
            Matricula: this.props.Matricula,
            Tipo: "",
            Unidade: "",
            Loading: false,
            permiteMovimentarAvista: true
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.Matricula !== this.props.Matricula) {
            this.setState(this.getState(), () => {
                this.componentDidMount();
            })
        }
    }

    async componentDidMount() {
        this.setState({ Unidade: this.props.Unidade});

        this.Trocar(3);
        var resultMatricula = await _movimentacoesService.GetByIdMatriculaMovimentacao(this.state.Matricula.idMatricula, 284360000);
        if (resultMatricula.statusCode != 0 || resultMatricula.statusCode == undefined) {
            Message.ExibeListMensagem(resultMatricula.messages)
            this.Trocar(4);
        } else if (this.props.ResponsavelPedagogico) {
            this.Trocar(5);
        } else {
            this.Trocar("");
        }
        var idUnidade = this.props.Unidade.id;
        var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.Matricula.idMatricula, idUnidade, 284360000, 0);
        this.setState({ permiteMovimentarAvista: permiteMovimentarAvista.value });
    }
    async Trocar(tipo) {
       try {
           this.setState({ Loading: true});
           if (tipo == 1 || tipo == 2) {
               const { Matricula, Unidade } = this.state;
               var response = await _movimentacoesService.PermiteMovimentarAvista(Matricula.idMatricula, Unidade.id, 284360000, tipo);
               if (response.value == false) {
                   var mensagem = response.messages && response.messages.length > 0 ? response.messages[0].text : "Erro ao tentar movimentar!";
                   Message.ExibeMensagem(mensagem, "2", false);
                   this.setState({ Loading: false });
                   return false;
               }
           }

           this.setState({ Tipo: tipo, Loading:false });
       } catch (e) {
           console.error(e)
       }
    }
    render() {
        const { Matricula, Tipo, Loading, permiteMovimentarAvista } = this.state;

        {
            if (!permiteMovimentarAvista && !this.props.ResponsavelPedagogico) {
                return (
                    <div >
                        <div className=" form-group d-flex justify-content-center">
                            <h3 style={{ color: '#0068b2' }}> <strong>AVISO</strong></h3>
                        </div>
                        <div className=" form-group d-flex justify-content-center">
                            <h5 style={{ color: '#0068b2' }}> O Template selecionado não permite movimentações onde o pagamento é Avista.</h5>
                        </div>
                    </div>
                );
            }
        }

        switch (Tipo) {
            case 1:
                return <Movimentacoes_responsavel
                    Matricula={Matricula}
                    ResponsavelPedagogico={this.props.ResponsavelPedagogico}
                    handleShowCsat={this.props.handleShowCsat}
                />;
                break;
            case 2:
                return <Responsavel_rateio
                    Matricula={Matricula}
                />;
                break;
            case 3:
                return (<div className="form-group d-flex justify-content-center"><img width="50" height="50" src="img/loading.gif" /></div>);
                break;
            case 4:
                return (<div className="form-group d-flex justify-content-center">Bloqueio de Matricula</div>);
                break;
            case 5:
                return (
                    <div className="row">
                        <div className="col"><button type="button" disabled={Loading ? 'disabled' : ''} className="btn btn-primary" onClick={() => this.Trocar(1)}>{Loading ? "Loading..." : "Trocar"}</button></div>
                    </div>
                )
                break;
            default:
                return (
                    <div className="row">
                        <div className="col"><button type="button" disabled={Loading ? 'disabled' : ''} className="btn btn-primary" onClick={() => this.Trocar(1)}>{Loading ? "Loading..." :"Trocar"  }</button></div>
                        <div className="col"><button type="button" disabled={Loading ? 'disabled' : ''} className="btn btn-primary" onClick={() => this.Trocar(2)}>{Loading ? "Loading..." : "Rateio"}</button></div>
                    </div>
                )
        }

    }
}

export default withUnidadeMatricula(TrocaResponsavelFinanceiro, "Responsável Financeiro", csatList.trocaResponsavelFinanceiro);
