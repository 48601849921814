import { createContext } from 'react';
import {Settings} from "../types/settings";
import getAmbiente from "../utils/getAmbiente";


export const defaultSettings: Settings = {
  colorPreset: getAmbiente(),
  contrast: 'normal',
  direction: 'ltr',
  layout: 'vertical',
  navColor: 'discrete',
  paletteMode: 'light',
  responsiveFontSizes: true,
  stretch: false
};

export interface State extends Settings {
  openDrawer: boolean;
  isInitialized: boolean;
}

export const initialState: State = {
  ...defaultSettings,
  isInitialized: false,
  openDrawer: false
};

export interface SettingsContextType extends State {
  handleDrawerClose: () => void;
  handleDrawerOpen: () => void;
  handleReset: () => void;
  handleUpdate: (settings: Settings) => void;
  isCustom: boolean;
}

export const SettingsContext = createContext<SettingsContextType>({
  ...initialState,
  handleDrawerClose: () => {},
  handleDrawerOpen: () => {},
  handleReset: () => {},
  handleUpdate: () => {},
  isCustom: false
});
