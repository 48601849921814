import React from 'react';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import AtualizarContrato from "../../components/AtualizarContrato/AtualizarContrato";
import csatList from "../../utils/csatList";

function AtualizarContratoPage(props) {
  return (
    <AtualizarContrato
      Matricula={props.Matricula}
      movimentacoes={true}
      handleShowCsat={props.handleShowCsat}
    />
  );
}

export default withUnidadeMatricula(AtualizarContratoPage, "Atualização de Contrato", csatList.atualizarContrato);