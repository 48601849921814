import React, {Component} from "react";
import '../../styles/css/app.css';
import * as _responsavelService from '../../service/responsavel-service';

import {PeopleFill} from 'react-bootstrap-icons';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import {AuthContext} from "../../context/AuthContext";
import CustomTable from '../../components/CustomTable';
import contextKeys from "../../utils/contextKeys";

class Responsaveis extends Component {
  constructor(props) {
    super(props);
    this.state = this.getState()
  }

  getState() {
      return {
          responsaveis: [],
          Matricula: this.props.Matricula,
          isLoading: false
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.Matricula !== this.props.Matricula) {
          this.setState(this.getState(), () => {
              this.componentDidMount()
          })
      }
  }

  async componentDidMount() {
    this.listResponsaveis();
  }

  async listResponsaveis() {
    this.setState({isLoading: true});
    var responsaveis = await _responsavelService.ListResponsaveisByMatricula(this.state.Matricula.idMatricula);
    this.setState({responsaveis: responsaveis ?? [], isLoading: false});
  }

  setLinkForContract(responsavel) {
    if (responsavel.urlContrato) {
      responsavel.urlContrato = <a target='_blank' href={responsavel.urlContrato}>Contrato</a>
    }
    return responsavel
  }

  render() {
    const {responsaveis, isLoading} = this.state;
    const tableConfigs = {
        selection: false,
        actions: false,
    }
    if (isLoading) {
      return <LoadingAnimation/>
    } else {
      return (
        <div className="row p-b-10">
          <fieldset className='p-b-10 p-t-10'>
              <AuthContext.Consumer>
                {({ appContext }) => {
                const { tables } = appContext || {};
                const colunasReponsavel = tables[contextKeys.tables.RESPONSAVEIS_TABLE]?.columns || [];
                return (
                    <div className='row p-b-10'>
                    <legend className="pull-left width-full">Responsáveis <PeopleFill className='fa-icon iconeAzul' /> </legend>
                      <div className='p-10'>
                          <CustomTable
                            columns={colunasReponsavel}
                            data={(responsaveis || []).map(this.setLinkForContract.bind(this))}
                            configs={tableConfigs}
                          />
                      </div>
                    </div>
                )
                }}
              </AuthContext.Consumer>
            <hr/>
          </fieldset>
        </div>
      );
    }
  }

}

export default withUnidadeMatricula(Responsaveis, 'Responsáveis');