import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function crmUnitControllerGroups({unitCrmId}) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            guardianCrmUnitControllerGroups
        },
        errors
    } = await client.query({
        query: gql`
            query GuardianCrmUnitControllerGroups($filter: GuardianControllerGroupsFilter) {
                guardianCrmUnitControllerGroups(filter: $filter) {
                    succeced
                    data {
                        id
                        uId
                        name
                    }
                }
            }
        `,
        variables: {
          filter: {
              unitCrmId
          }
        }
    })

    dispatchGraphError(errors);

    return guardianCrmUnitControllerGroups;
}

export async function getAdByUnit(unitId) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            getAdByUnit
        },
        errors
    } = await client.query({
        query: gql`
            query GetAdByUnit($filter: GetAdByUnitFilter) {
                getAdByUnit(filter: $filter) {
                    id
                    nome
                    imagemUrl
                    idPortaria
                    publicURL
                    criadoEm
                }
            }
        `,
        variables: {
            filter: {
                unitId
            }
        }
    })
    
    dispatchGraphError(errors);
    
    return getAdByUnit;
}

export async function publishAd(data) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            publishAd
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation PublishAd($data: PublishAdInput) {
                publishAd(data: $data)
            }
        `,
        variables: {
            data
        }
    })
    
    dispatchGraphError(errors);
    
    return publishAd;
}
