import React, { useEffect, useState, Fragment } from 'react'
import '../../styles/css/app.css'
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import Select from '@mui/material/Select';
import SendIcon from '@mui/icons-material/Send';
import MenuItem from '@mui/material/MenuItem';
import withUnidade from "../../hocs/withUnidade.js";
import Container from '@mui/material/Container';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Grid from '@mui/material/Grid';
import CustomTable from '../../components/CustomTable';
import * as _matriculaService from '../../service/matricula-service'
import Swal from "sweetalert2";
import { selectedMatriculaState } from "../../recoil/atoms/selectedMatriculaState";
import { selectedAnoLetivoState } from "../../recoil/atoms/selectedAnoLetivoState";
import { selectedUnidadeState } from "../../recoil/atoms/selectedUnidadeState";
import { useRecoilState } from "recoil";
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../context/AuthContext'


interface MatriculaType {
    codigoMatricula: string,
    codigoMatriculaErp: string,
    anoLetivo: string,
    estudante_Name: string,
    codigoCliente: string,
    tipoMatricula: string,
    segmento: string,
    servico: string,
    turma_Name: string,
    idUnidade: string
}

const parmetrersEnum = {
    CodigoMatricula: 'Código da Matrícula',
    CodigoMatriculaErp: 'Código da Matrícula ERP',
    Estudante_Name: 'Nome do Aluno',
    CodigoCliente: 'Código do Cliente'
};

const Home = () => {
    const [Matriculas, setMatriculas] = useState<MatriculaType[]>([]);
    const [refreshAuthContext, setRefreshAuthContext] = useState('initial');
    const [matricula, setMatricula] = useRecoilState(selectedMatriculaState);
    const [unidade, setUnidade] = useRecoilState(selectedUnidadeState);
    const [year, setYear] = useRecoilState(selectedAnoLetivoState);
    const [selectedRow, setSelectedRow] = useState([]);
    const [anoLetivo, setAnoLetivo] = useState('');
    const [loadMoreMatriculas, setLoadMoreMatriculas] = useState(false);
    const yearNow = new Date().getFullYear();
    const [loadingMatriculas, setLoadingMatriculas] = useState(false);
    const [isLoadingScroll, setIsLoadingScroll] = useState(true);
    const history = useHistory();
    const sortedKeys = Object.keys(parmetrersEnum).sort((a, b) => parmetrersEnum[a].localeCompare(parmetrersEnum[b]));

    const [paramFilter, setParamFilter] = useState({
        name: 'CodigoMatricula',
        value: ''
    });
    const [request, setRequest] = useState({
        AnoLetivo: anoLetivo,
        [paramFilter.name]: paramFilter.value,
        IdUnidade: sessionStorage.getItem('@SECRETARIA/idUnidade') ? sessionStorage.getItem('@SECRETARIA/idUnidade') : unidade?.id,
        TipoFiltro: 'matriculas'
    });
    const [pagination, setPagination] = useState({
        page: 1,
        size: 15
    })
    const tableConfigs = {
        selection: false,
        editing: false,
        isEndedList: true,
        actions: [
            (row) => {
                return {
                    id: "select",
                    label: <div className='staticAction'><HowToRegIcon /> Selecionar</div>,
                }
            }
        ],
        onClickAction: (action, row) => {
            switch (action.id) {
                case "select":
                    handleSelect(row);
                    break;
                default:
                    console.warn("Unhandled option: ", action);
                    break;
            }
        },
    }
    const selecionarMatricula = (row: any) => {
        setMatricula(row);
        setYear(row.anoLetivo_Name);
        history.push("/matricula/detalhes");
    }

    const handleEnterKeyPress = (event) => {
        if (event.key === 'Enter') {
            getFirstMatriculasFiltradas();
        }
    };

    const getFirstMatriculasFiltradas = async () => {
        setLoadingMatriculas(true);
        const sendRequest = {
            AnoLetivo: anoLetivo,
            [paramFilter.name]: paramFilter.value,
            IdUnidade: request.IdUnidade,
            TipoFiltro: 'matriculas'
        }
        setRequest(sendRequest);
        setMatriculas([]);
        setPagination({ page: 1, size: 15 });
        setLoadingMatriculas(false);
        setRefreshAuthContext((prev) => (prev === 'initial' ? 'reset' : 'initial'));
    }
    useEffect(() => {
        async function getMatriculas() {
            setIsLoadingScroll(false);
            var matriculas = await _matriculaService.ListMatriculasByPage(request, pagination)
            if (matriculas.rows.length < pagination.size) {
                setIsLoadingScroll(false)
                if (matriculas.rows.length === 0 && Matriculas.length === 0) {
                    Swal.fire({
                        title: 'Nenhuma Matrícula encontrada!',
                        icon: 'warning',
                        showCancelButton: false,
                        confirmButtonColor: '#3085d6',
                        cancelButtonColor: '#d33',
                        confirmButtonText: 'Ok',
                        showLoaderOnConfirm: true,
                        allowOutsideClick: () => !Swal.isLoading(),
                        preConfirm: async () => {
                            return true;
                        }
                    })
                    return;
                }
            } else {
                setIsLoadingScroll(true)
            }
            setMatriculas((prevMatriculas) => [...prevMatriculas, ...matriculas.rows]);
            setLoadingMatriculas(false);
        }
        getMatriculas();
    }, [pagination]);

    const handleSelect = (row) => {
        selecionarMatricula(row);
    }

    const getMoreMatriculas = async () => {
        setPagination((prevPagination) => { return { page: prevPagination.page + 1, size: prevPagination.size } });
        setLoadMoreMatriculas(!loadMoreMatriculas);
    }
    const rootInputStyles = {
        '&:focus-within fieldset, &:focus-visible fieldset': {
            boxShadow: 'none !important',
        },
    };
    return (
        <Fragment>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                }}
            >
                <Container maxWidth="xl">
                    <Typography
                        variant="h4"
                        sx={{
                            mb: 3
                        }}
                    >
                        Matrículas
                    </Typography>
                    <Stack spacing={3}>
                        <Stack spacing={4}>
                            <>
                                <Typography variant='h6'>Pesquisa</Typography>
                                <Grid container spacing={2}>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={3}>
                                        <Select
                                            fullWidth
                                            name="anoLetivo"
                                            value={anoLetivo}
                                            onChange={(e) => setAnoLetivo(e?.target.value)}
                                            displayEmpty
                                            sx={{
                                                '& legend': {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            <MenuItem value="">
                                                <em>Todos os Anos letivos</em>
                                            </MenuItem>
                                            {Array.from({ length: (yearNow - 2021) + 2 }, (_, i) => 2021 + i).map((year) => (
                                                <MenuItem key={year} value={String(year)}>
                                                    {year}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        md={6}
                                        lg={3}>
                                        <Select
                                            fullWidth
                                            name="selectedFilter"
                                            variant='outlined'
                                            value={parmetrersEnum[paramFilter.name]}
                                            onChange={(e) =>
                                                setParamFilter({
                                                    name: sortedKeys.find(key => parmetrersEnum[key] === e?.target.value) || '',
                                                    value: ''
                                                })
                                            }
                                            displayEmpty
                                            sx={{
                                                '& legend': {
                                                    display: 'none'
                                                }
                                            }}
                                        >
                                            {sortedKeys
                                                .map((choice) => (
                                                    <MenuItem key={choice} value={parmetrersEnum[choice]}>
                                                        {parmetrersEnum[choice]}
                                                    </MenuItem>
                                                ))}
                                        </Select>
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={4}>
                                        <TextField
                                            fullWidth
                                            name={paramFilter.name}
                                            label={parmetrersEnum[paramFilter.name]}
                                            value={paramFilter.value}
                                            onChange={(e) => setParamFilter({ name: e.target.name, value: e.target.value })}
                                            variant="outlined"
                                            InputProps={{
                                                sx: {
                                                    ...rootInputStyles,
                                                },
                                            }}
                                            inputProps={{
                                                maxLength: paramFilter.name !== 'Estudante_Name' ? 15 : null,
                                            }}
                                            onKeyUp={handleEnterKeyPress}
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={12}
                                        lg={2}>
                                        <Button size="large" variant="contained" onClick={() => getFirstMatriculasFiltradas()} endIcon={<SendIcon />}>
                                            Buscar
                                        </Button>
                                    </Grid>
                                </Grid>
                                <Grid container spacing={3}>
                                    <AuthContext.Consumer key={refreshAuthContext} >
                                        {({ appContext }) => {
                                            const { tables } = appContext || {}
                                            const matriculasColumns =
                                                tables['listmatriculas-table']?.columns || []
                                            return (
                                                <CustomTable
                                                    columns={matriculasColumns}
                                                    data={Matriculas || []}
                                                    selected={selectedRow}
                                                    onEditRow={handleSelect}
                                                    configs={tableConfigs}
                                                    isLoading={loadingMatriculas}
                                                    isLoadingScroll={isLoadingScroll}
                                                    infinityLoadingFunc={getMoreMatriculas}
                                                    staticAction={true}
                                                />
                                            )
                                        }}
                                    </AuthContext.Consumer>
                                </Grid>
                            </>
                        </Stack>
                    </Stack>
                </Container>
            </Box>
        </Fragment>
    )
}

export default withUnidade(Home)
