const tipoMovimentacaoEnum = {
  TrocaResponsavelFinanceiro: 284360000,
  AtualizarContrato: 284360008,
  TrocaTurno: 284360001,
  TrocaCurso: 284360002,
  TrocaTurma: 284360003,
  TrocaPlanoPagamento: 284360004,
  TrocaMetodoPagamento: 284360005,
  TrocaBolsa: 284360006,
  Cancelamento: 284360007,
  TrocaResponsavelPedagogico: 284360050 //Numero provisorio
}

Object.freeze(tipoMovimentacaoEnum);

export default tipoMovimentacaoEnum;