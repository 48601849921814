import React, { Component } from "react";
import { Modal, Button } from "react-bootstrap";
import "./styles.css";
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import CloseFullscreenIcon from '@mui/icons-material/CloseFullscreen';
export class ModalBase extends Component {
  static displayName = ModalBase.name;
  state = {
    fullscreen: false,
  };

  constructor(props) {
    super(props);

    this.state.fullscreen = props.fullscreen;
  }

  handleToggleFullscreen = () => {
    localStorage.setItem("modal-fullscreen", !this.state.fullscreen);
    this.setState({ fullscreen: !this.state.fullscreen });
  }

  render() {
    return (
      <Modal
        className="seb-secretaria-component-modal-base"
        backdrop="static"
        keyboard={false}
        dialogClassName={this.state.fullscreen ? "" : "modal-90w"}
        autoFocus={true}
        show={this.props.show}
        onHide={() => this.props.Fechar()}
        animation={false}
        fullscreen={this.state.fullscreen}
      >
        <Modal.Header
          style={this.props.titulo === undefined ? { display: "none" } : {position: "relative"}}
          closeButton
        >
          <h1 ml="15px" fz="24px">
            {this.props.titulo}
          </h1>
          <span
            role="button"
            style={{
              maxWidth: "32px",
              position: "absolute",
              right: "60px",
            }}
            onClick={this.handleToggleFullscreen}
          >
                    {this.state.fullscreen ? <CloseFullscreenIcon /> : <OpenInFullIcon />}
                  </span>
        </Modal.Header>
        <Modal.Body >
          {this.props.children}
        </Modal.Body>
        {this.props?.DescBtnSecundario !== undefined &&
          <Modal.Footer style={Object.assign(this.props.backgroundImg !== undefined ? { background: "none" } : {}, styles.footer)}  >
            {/*<Button*/}
            {/*    name="Fechar"*/}
            {/*    onClick={() => this.props.Fechar()}*/}

            {/*    style={styles.btn}*/}
            {/*>*/}


            {/*    {this.props.DescBtnFechar !== undefined*/}
            {/*        ? this.props.DescBtnFechar*/}
            {/*        : "Fechar"}*/}
            {/*</Button>*/}
            <Button
              name="Cancelar"
              variant="secondary"
              style={
                this.props.DescBtnSecundario === undefined
                  ? { display: "none" }
                  : {}
              }
              onClick={() => this.props.BtnSecundario()}
              width="156px"
            >
              {this.props.DescBtnSecundario !== undefined
                ? this.props.DescBtnSecundario
                : "Cancelar"}
            </Button>
          </Modal.Footer>
        }
      </Modal>
    );

  }
}

const styles = {
  footer: {

  },
  btn: { "width": "342.05px", "height": "50px", "backgroundColor": "#0068B2", "borderRadius": "6px", "fontFamily": "Inter", "fontStyle": "normal", "fontWeight": "600", "fontSize": "16px", "lineHeight": "24px" }

}