import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import Drawer from '@mui/material/Drawer';
import Stack from '@mui/material/Stack';
import {useTheme} from '@mui/material/styles';
import {Scrollbar} from '../components/scrollbar';
import {SideNavSection} from './side-nav-section';
import {RouterLink} from "../components/router-link";
import Box from "@mui/material/Box";
import {Logo} from "../components/logo";
import {useLocation} from "react-router-dom";
import {useRecoilValue} from "recoil";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import Version from "../components/Version/Version";
import useMediaQuery from "@mui/material/useMediaQuery";
import { SvgIcon, Button, Typography } from '@mui/material';
import SourceIcon from '@mui/icons-material/Source';

const SIDE_NAV_WIDTH = 280;

const useCssVars = (color) => {
  const theme = useTheme();

  return useMemo(() => {
    switch (color) {
      case 'blend-in':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          };
        } else {
          return {
            '--nav-bg': theme.palette.background.default,
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.neutral[100],
            '--nav-logo-border': theme.palette.neutral[100],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.text.secondary,
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900]
          };
        }

      case 'localhost':
        return {
          '--nav-bg': theme.palette.primary.dark,
          '--nav-color': theme.palette.neutral[100],
          '--nav-border-color': theme.palette.neutral[100],
          '--nav-logo-border': theme.palette.neutral[100],
          '--nav-section-title-color': theme.palette.neutral[100],
          '--nav-item-color': theme.palette.neutral[100],
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.text.primary,
          '--nav-item-disabled-color': theme.palette.neutral[100],
          '--nav-item-icon-color': theme.palette.neutral[100],
          '--nav-item-icon-active-color': theme.palette.text.primary,
          '--nav-item-icon-disabled-color': theme.palette.neutral[100],
          '--nav-item-chevron-color': theme.palette.neutral[100],
          '--nav-scrollbar-color': theme.palette.neutral[900]
        };
        
      case 'development':
        return {
          '--nav-bg': theme.palette.primary.dark,
          '--nav-color': theme.palette.neutral[100],
          '--nav-border-color': theme.palette.neutral[100],
          '--nav-logo-border': theme.palette.neutral[100],
          '--nav-section-title-color': theme.palette.neutral[100],
          '--nav-item-color': theme.palette.neutral[100],
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.text.primary,
          '--nav-item-disabled-color': theme.palette.neutral[100],
          '--nav-item-icon-color': theme.palette.neutral[100],
          '--nav-item-icon-active-color': theme.palette.text.primary,
          '--nav-item-icon-disabled-color': theme.palette.neutral[100],
          '--nav-item-chevron-color': theme.palette.neutral[100],
          '--nav-scrollbar-color': theme.palette.neutral[900]
        };

      case 'homolog':
        return {
          '--nav-bg': theme.palette.primary.dark,
          '--nav-color': theme.palette.neutral[100],
          '--nav-border-color': theme.palette.neutral[100],
          '--nav-logo-border': theme.palette.neutral[100],
          '--nav-section-title-color': theme.palette.neutral[100],
          '--nav-item-color': theme.palette.neutral[100],
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.text.primary,
          '--nav-item-disabled-color': theme.palette.neutral[100],
          '--nav-item-icon-color': theme.palette.neutral[100],
          '--nav-item-icon-active-color': theme.palette.text.primary,
          '--nav-item-icon-disabled-color': theme.palette.neutral[100],
          '--nav-item-chevron-color': theme.palette.neutral[100],
          '--nav-scrollbar-color': theme.palette.neutral[900]
        };

      case 'production':
        return {
          '--nav-bg': theme.palette.primary.dark,
          '--nav-color': theme.palette.neutral[100],
          '--nav-border-color': theme.palette.neutral[100],
          '--nav-logo-border': theme.palette.neutral[100],
          '--nav-section-title-color': theme.palette.neutral[100],
          '--nav-item-color': theme.palette.neutral[100],
          '--nav-item-hover-bg': theme.palette.action.hover,
          '--nav-item-active-bg': theme.palette.action.selected,
          '--nav-item-active-color': theme.palette.neutral[400],
          '--nav-item-disabled-color': theme.palette.neutral[100],
          '--nav-item-icon-color': theme.palette.neutral[100],
          '--nav-item-icon-active-color': theme.palette.neutral[100],
          '--nav-item-icon-disabled-color': theme.palette.neutral[100],
          '--nav-item-chevron-color': theme.palette.neutral[100],
          '--nav-scrollbar-color': theme.palette.neutral[900]
        };

      case 'discrete':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[900],
            '--nav-color': theme.palette.neutral[100],
            '--nav-border-color': theme.palette.neutral[700],
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[600],
            '--nav-item-icon-color': theme.palette.neutral[500],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[700],
            '--nav-item-chevron-color': theme.palette.neutral[700],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          };
        } else {
          return {
            '--nav-bg': theme.palette.neutral[50],
            '--nav-color': theme.palette.text.primary,
            '--nav-border-color': theme.palette.divider,
            '--nav-logo-border': theme.palette.neutral[200],
            '--nav-section-title-color': theme.palette.neutral[500],
            '--nav-item-color': theme.palette.neutral[500],
            '--nav-item-hover-bg': theme.palette.action.hover,
            '--nav-item-active-bg': theme.palette.action.selected,
            '--nav-item-active-color': theme.palette.text.primary,
            '--nav-item-disabled-color': theme.palette.neutral[400],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[400],
            '--nav-item-chevron-color': theme.palette.neutral[400],
            '--nav-scrollbar-color': theme.palette.neutral[900]
          };
        }

      case 'evident':
        if (theme.palette.mode === 'dark') {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          };
        } else {
          return {
            '--nav-bg': theme.palette.neutral[800],
            '--nav-color': theme.palette.common.white,
            '--nav-border-color': 'transparent',
            '--nav-logo-border': theme.palette.neutral[700],
            '--nav-section-title-color': theme.palette.neutral[400],
            '--nav-item-color': theme.palette.neutral[400],
            '--nav-item-hover-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-bg': 'rgba(255, 255, 255, 0.04)',
            '--nav-item-active-color': theme.palette.common.white,
            '--nav-item-disabled-color': theme.palette.neutral[500],
            '--nav-item-icon-color': theme.palette.neutral[400],
            '--nav-item-icon-active-color': theme.palette.primary.main,
            '--nav-item-icon-disabled-color': theme.palette.neutral[500],
            '--nav-item-chevron-color': theme.palette.neutral[600],
            '--nav-scrollbar-color': theme.palette.neutral[400]
          };
        }

      default:
        return {};
    }
  }, [theme, color]);
};

export const SideNav = (props) => {
  const {
    color = 'evident',
    sections = [],
    onClose,
    open
  } = props;
  const {pathname} = useLocation();
  const cssVars = useCssVars(color);
  const theme = useTheme();
  const background = theme.palette.background.default;
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const docUrl = process.env.REACT_APP_DOCUMENTATION_URL;
  const {
    logo
  } = selectedUnidade || {};
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  return (
    <Drawer
      anchor="left"
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          ...cssVars,
          backgroundColor: 'var(--nav-bg)',
          borderRightColor: 'var(--nav-border-color)',
          borderRightStyle: 'solid',
          borderRightWidth: 1,
          color: 'var(--nav-color)',
          width: SIDE_NAV_WIDTH,
        }
      }}
      variant={lgUp ? "permanent" : "temporary"}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%'
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)'
          }
        }}
      >
        <Stack sx={{ height: '100%' }}>
          <Stack
            alignItems="center"
            direction="row"
            spacing={2}
            sx={{ p: 3 }}
          >
            <Box
              component={RouterLink}
              href={"/"}
              sx={{
                backgroundColor: logo && background,
                borderColor: 'var(--nav-logo-border)',
                borderRadius: 1,
                borderStyle: 'solid',
                borderWidth: 1,
                display: 'flex',
                margin: '0 auto',
                height: !logo ? 120 : 50,
                p: '4px',
                width: "auto",
                maxWidth: !logo ? 200 : 150
              }}
            >
              <Logo />
            </Box>
          </Stack>
          <Stack
            component="nav"
            spacing={2}
            sx={{
              flexGrow: 1,
              px: 2
            }}
          >
            {sections.map((section, index) => (
              <SideNavSection
                items={section.items}
                key={index}
                pathname={pathname}
                subheader={section.subheader}
              />
            ))}
          </Stack>
          {docUrl && <Box sx={{ p:8, pl:3, pr: 3}}>
            <Typography
              color="neutral.200"
              variant="subtitle1"
            >
              Precisa de Ajuda?
            </Typography>
            <Typography
              color="neutral.200"
              sx={{ mb: 1 }}
              variant="body2"
            >
              Confira a documentação
            </Typography>
            <Button
              component="a"
              fullWidth
              color="primary"
              href={process.env.REACT_APP_DOCUMENTATION_URL}
              startIcon={(
                <SvgIcon>
                  <SourceIcon />
                </SvgIcon>
              )}
              target="_blank"
              variant="contained"
            >
              Base de Conhecimento
            </Button>
          </Box>}
          <Box sx={ docUrl ? { pl: 3, pr: 3, textAlign: 'center' } : {p: 3, textAlign: 'center'}}>
            <Version/>
          </Box>
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SideNav.propTypes = {
  color: PropTypes.oneOf(['blend-in', 'discrete', 'evident', 'localhost', 'development', 'homolog', 'production']),
  sections: PropTypes.array
};
