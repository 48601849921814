import {useEffect} from "react";

interface Command {
  handler: (e: KeyboardEvent) => boolean;
  onMatch?: VoidFunction;
  onPress?: VoidFunction;
  onRelease?: VoidFunction;
  ignore?: boolean;
}

interface ShortCutsProps {
  commands: Command[]
}

const ShortCuts = ({commands}: ShortCutsProps) => {
  useEffect(() => {
    const handler = (e: KeyboardEvent) => {
      commands.forEach(c => {
        if (c.handler(e)) {
          if (c.onMatch) c.onMatch();
          if (c.onRelease) c.onRelease();
        }
      });
    };

    const ignore = (e: KeyboardEvent) => {
      commands.forEach(c => {
        if (c.handler(e)) {
          if (false !== c.ignore) e.preventDefault();
          if (c.onPress) c.onPress();
        }
      });
    };

    window.addEventListener("keyup", handler);
    window.addEventListener("keydown", ignore);

    return () => {
      window.removeEventListener("keyup", handler);
      window.removeEventListener("keydown", ignore);
    };
  }, []);

  return null;
};

export default ShortCuts;