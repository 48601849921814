import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetAll() {
  /** @type ApolloClient<NormalizedCacheObject> */
  const client = window.apolloClient;
  const {
    data: {
      listUnidades
    },
    error,
    errors
  } = await client.query({
    query: gql`
    query ListUnidades {
      listUnidades {
        id
        nome
        bairro
        bancoPagamentoId
        bloqueioContratacao
        cep
        cidade
        cnpj
        codigoERP
        complemento
        dataAreaId
        data_limite_desconto_adiantamento
        descontoEntradaVista
        diasVencimentoPagamento
        endereco
        estado
        grupo
        logo
        logradouro
        mensagemBloqueio
        pais
        parametroPagamentoId
        razaoStatusValue
        site
        siteUrl
        statusValue
        telefone
        urlFluxoAgendamento
        urlLandingPage
        urlVideoMatricula
        possuiFacial
      }
    }
    `
  })
  if (error) {
    alert("Erro ao listar matriculas.");
  }

  dispatchGraphError(errors);

  return listUnidades;
}