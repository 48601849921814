import {CancelTwoTone, ContentCopyOutlined, EditTwoTone} from "@mui/icons-material";
import React from "react";

const getTableActions = ({editingRows}) => {
  return [
    (row) => {
      if (editingRows.includes(row.id)) {
        return {
          id: "cancel-edit",
          label: <><CancelTwoTone/> Cancelar edição</>,
        }
      } else {
        return {
          id: "edit",
          label: <><EditTwoTone/> Habilitar edição</>,
        }
      }
    },
    {
      id: "copy",
      label: <><ContentCopyOutlined/> Duplicar esta linha logo abaixo</>,
    }
  ]
}
export default getTableActions;