import React from 'react'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import Tooltip from '@mui/material/Tooltip';
import IconButton from '@mui/material/IconButton';
import InfoIcon from '@mui/icons-material/Info';
import EditableTD from './EditableTD'

function Row ({
  selection = <> </>,
  actions = <> </>,
  columns = [],
  data = {},
  onDoubleClick = row => event => {},
  onClick = event => {},
  onKeyPress = event => {},
  editing = false,
  isSelected = false,
  isStaticAction = false,
  onChange
}) {
  const handleOnDoubleClick = event => {
    event.preventDefault()
    if (onDoubleClick) {
      onDoubleClick(event)
    }
  }
  const renderRowDataFor = row => column => {
    const { id, editable, type, step, min, max, options } = column
    const text = row[id]
    if (editable) {
      return (
        <EditableTD
          key={id}
          type={type}
          editable={editing}
          value={text}
          onChange={value => {
            if (onChange) {
              onChange(row.id, id, value)
            }
          }}
          handleOnDoubleClick={handleOnDoubleClick}
          onKeyPress={onKeyPress}
          step={step}
          min={min}
          max={max}
          options={options}
        />
      )
    }
    return (
      <TableCell
        sx={{ color: '#FFFFFF', fontWeight: 'bold' }}
        key={id}
        onDoubleClick={handleOnDoubleClick}
      >
        <Typography variant="subtitle2" sx={{color: 'var(--primary-color-disable)', fontWeight: 'bold'}}>
          {row[id]}
        </Typography>
        <Tooltip title={row[id]} className='icon'>
          <IconButton>
            <InfoIcon sx={{color: 'var(--primary-color-disable)'}}/>
          </IconButton>
        </Tooltip>
      </TableCell>
    )
  }
  return (
    <tr
      key={data.id}
      className={[isSelected && !isStaticAction ? 'trSelected' : ''].join(' ')}
      onClick={onClick}
    >
      {selection}
      {columns.map(renderRowDataFor(data))}
      {isSelected && actions}
    </tr>
  )
}

export default Row
