import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {Unidade} from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";

export const selectedUnidadeState = atom<Unidade>({
  key: 'selectedUnidadeState',
  default: undefined,
  effects: [
    persistAtomEffect
  ]
});
