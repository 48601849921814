import { v4 as uuidv4 } from 'uuid';
export default class SalesOrder {
  id = uuidv4();
  CustAccount = "HEBC-001639";
  ItemId = "U000352";
  Month = 4;
  Year = 2023;
  AmountCur = 388.88;
  DiscAmountCur = 194.44;
  configId = "1ª PARCELA";
  InventLocationId = "3306_T_61";
  FiscalEstablishment = "3306";
  PaymSched = "Plano 9";
  Scholarship = 194.44;
  GrossAmount = 3500.00;
  MethodDiscount = 0.00;
  BillingItemId = "U000352";
  MatriculaId = "HEB-014274";
  TypeOfMovement = 1;
  StartSchoolYear = "2023-01-01T12:00:00";
  CashDiscAmount = 0.0;
  MethodDiscountPercentage = 0.0;
  NetValue = 194.44;
  CashDiscAmountCur = 0.0;
  DeliveryDate = "2023-04-10T12:00:00";
  InstallmentNum = 1;
  EnrollmentSalesOrderRecId = 0;
  SegmentCode = "PV";
  ScholarshipPercentage = 50.0;

  constructor(
    MatriculaID
  ) {
    this.MatriculaID = MatriculaID;
  }

  toJSON() {
    const json = {...this};
    delete json.id;
    return json;
  }
}
