import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import ProfilePicture from "../ProfilePicture/ProfilePicture";
import Button from "@mui/material/Button";
import {RefreshTwoTone, ZoomOutTwoTone} from "@mui/icons-material";
import {Alert, AlertTitle, Grow, Link, Tooltip} from "@mui/material";
import Box from "@mui/material/Box";
import React, {useEffect, useMemo, useRef, useState} from "react";
//@ts-ignore
import {Anexo, ImageQualityReport, Maybe} from "seb-graph-api-types/generated";
import {Theme} from "@mui/material/styles/createTheme";
import Input from '@mui/material/Input';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import SvgIcon from "@mui/material/SvgIcon";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import {Modal} from "../modal";
import reportCategories from "./reportCategories";

interface Props {
  title?: string
  value?: Maybe<Anexo>
  onClick?: () => void
  onUpload?: (event: React.ChangeEvent<HTMLInputElement>, base64: string) => void
  onRestore?: () => void
  onRemove?: () => void
  disabled?: boolean
  loading?: boolean
  width?: string | number
  height?: string | number
  noImageIcon?: any
  infoTitle?: string
  infoContent?: any
  onClickInfo?: () => void
  warning?: string | null
}

function AnexoFoto(props: Props) {
  const {
    title = "Foto",
    value,
    onUpload,
    onRestore,
    onRemove,
    onClick,
    disabled = false,
    warning = null,
    loading,
    width,
    height,
    noImageIcon,
    infoTitle,
    infoContent,
    onClickInfo
  } = props;
  const {
    publicURL = "",
    props: AnexoProps
  } = value ?? {}
  const [modalOpen, setModalOpen] = useState(false);
  const [internalValue, setInternalValue] = useState<Maybe<Anexo> | null | undefined>(null);
  const inputRef = useRef<HTMLInputElement>(null);
  const [base64, setBase64] = useState<string | null>(null);
  const [pendingRemove, setPendingRemove] = useState(false);
  const reports = useMemo<ImageQualityReport>(
    () => AnexoProps?.metadata?.analysisDecoded?.imageQualityReport ?? {} as ImageQualityReport,
    [value]
  );
  const imageQualityReportKeys = useMemo(
    () => Object.keys(reports),
    [value]
  );
  const finalScore = useMemo(
    () => reports['finalScore'],
    [value]
  );

  const showStats = ((base64 === null && publicURL !== "" && !pendingRemove) && (publicURL !== "" && !pendingRemove));

  useEffect(() => {
    if (value !== internalValue) {
      setPendingRemove(false)
      setBase64(null)
      setInternalValue(value)
    }
  }, [value])

  const renderIcon = (key: string, index: number) => {
    const score = reports[key]
    if (!score) return null

    const category = reportCategories[key]

    if (!category) return null

    const message = category.message[score] ?? ""
    const image = category.image[score] ?? null

    if (!image) return null;

    const colors = {
      GOOD: (theme: Theme) => theme.palette.success.main,
      ACCEPTABLE: (theme: Theme) => theme.palette.warning.main,
      BAD: (theme: Theme) => theme.palette.error.main
    }

    return (
      <Grow
        key={index}
        in
        style={{transformOrigin: '0 0 0'}}
        {...(index !== 0 ? {timeout: 1000} : {})}
      >
        <Tooltip
          title={message}
        >
          <Box
            sx={{
              backgroundColor: colors[score],
              color: 'white',
              width: '34px',
              height: '34px',
              borderRadius: '10px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            {image()}
          </Box>
        </Tooltip>
      </Grow>
    )
  }

  const handleClickUpload = () => {
    if (disabled) return;

    if (onClick) {
      onClick()
      return;
    }

    if (inputRef && inputRef.current) {
      inputRef.current.click();
    }
  }

  const handleFileSelection = async (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event?.target?.files?.item(0)
    if (file) {
      const encoded = await getBase64(file);
      setBase64(encoded)
      if (onUpload) onUpload(event, encoded)
    }
  }

  const getBase64 = async (file: Blob): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.readAsDataURL(file)
      reader.onload = () => {
        //@ts-ignore
        resolve(reader.result)
      }
      reader.onerror = reject
    })
  }

  const handleRestore = () => {
    setBase64(null)
    setPendingRemove(false)
    if (inputRef && inputRef.current) {
      inputRef.current.value = ""
    }

    if (onRestore) onRestore()
  }

  const handleRemove = () => {
    setPendingRemove(true)
    setBase64(null)
    if (onRemove) onRemove()
  }
  
  return (
    <>
      <Modal
        open={modalOpen}
        onClose={() => setModalOpen(false)}
        title=""
        maxWidth="auto"
        disableExpand
        disableHeader
      >
        <ProfilePicture
          url={(pendingRemove && !base64) ? "" : base64 || publicURL}
          onClick={() => setModalOpen(false)}
          width="auto"
          height="100%"
          icon={modalOpen ? <ZoomOutTwoTone/> : <ZoomInIcon/>}
          placeholder={modalOpen ? "Recolher" : "Expandir"}
          noImageIcon={noImageIcon}
        />
      </Modal>

      <Stack
        spacing={0}
        flexDirection="row"
        justifyContent="center"
        sx={{
          mb: 3
        }}
      >
        <Stack
          direction="row"
          justifyContent="center"
        >
          <Stack
            spacing={2}
          >
            <Typography
              variant="h6"
              sx={{
                textAlign: "center"
              }}
            >
              {title}
            </Typography>

            <ProfilePicture
              url={(pendingRemove && !base64) ? "" : base64 || publicURL}
              color={finalScore === "BAD" ? "red" : undefined}
              disableHover={disabled}
              onClick={() => setModalOpen(true)}
              height={height}
              width={width}
              loading={loading}
              noImageIcon={noImageIcon}
            />

            <Input
              inputRef={inputRef}
              type="file"
              onChange={handleFileSelection}
              sx={{
                display: "none"
              }}
              inputProps={{
                accept: "image/png, image/jpeg"
              }}
            />

            {(infoTitle || infoContent) && (
              <Alert
                severity="info"
                variant="standard"
                sx={{
                  maxWidth: 200,
                  ml: 'auto',
                  mr: 'auto'
                }}
              >
                {(infoTitle !== null) && <AlertTitle>{infoTitle}</AlertTitle>}
                {onClickInfo ? <Link
                  color="text.primary"
                  sx={{ cursor: 'pointer' }}
                  variant="subtitle2"
                  onClick={onClickInfo}
                >
                  {infoContent}
                </Link> : infoContent}
              </Alert>
            )}

            {!loading && (
              <>
                <Button
                  color="primary"
                  variant="contained"
                  startIcon={<SvgIcon><AttachFileIcon/></SvgIcon>}
                  onClick={handleClickUpload}
                  disabled={disabled}
                >
                  Enviar anexo
                </Button>
              </>
            )}

            <Grow
              in={!!(base64 || pendingRemove)}
              easing="ease-out"
              mountOnEnter
              unmountOnExit
            >
              <Button
                type="button"
                color="info"
                startIcon={<RefreshTwoTone/>}
                onClick={handleRestore}
                disabled={disabled}
              >
                Restaurar
              </Button>
            </Grow>
          </Stack>
        </Stack>

        {(showStats && !loading) &&
          <Stack
            spacing={2}
            useFlexGap
            flexWrap="wrap"
            sx={{
              maxHeight: 300,
              mt: 5,
              ml: 2
            }}
          >
            {imageQualityReportKeys.map(renderIcon)}
          </Stack>
        }
      </Stack>

      {showStats && finalScore === "BAD" && !disabled && (
        <Alert
          severity="error"
          variant="outlined"
          sx={{
            maxWidth: 200,
            ml: 'auto',
            mr: 'auto'
          }}
        >
          <AlertTitle>Atenção</AlertTitle>
          Tirar nova foto!
        </Alert>
      )}
      {warning && (
        <Alert
          severity="warning"
          variant="outlined"
          sx={{
            maxWidth: 200,
            ml: 'auto',
            mr: 'auto'
          }}
        >
          {warning}
        </Alert>
      )}
    </>
  )
}

export default AnexoFoto;
