import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function BuscaResponsaveisMatricula(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            pagamentoBuscaResponsaveisMatricula
        },
        error,
        errors,
    } = await client.query({
        query: gql`
            query PagamentoBuscaResponsaveisMatricula($request: FindResponsible) {
              pagamentoBuscaResponsaveisMatricula(request: $request) {
                dataAreaId
                matriculaId
                condicaoDePagamento
                metodoDePagamento
                rateio
                responsavel
                nomeResponsavel
                responsavelContratual
                tipoResponsavelMatricula
                valor
              }
            }
        `, 
        variables: {
            request
        }
    })

    dispatchGraphError(errors);

    if (error) {
        const message = error[0].message;
        alert(message);
    }

    return pagamentoBuscaResponsaveisMatricula;
}



export async function BuscaMetodoAtual(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            pagamentoBuscaMetodoAtual
        },
        errors
    } = await client.query({
        query: gql`
            query Query($request: MetodoPagamentoPortalRequest) {
                pagamentoBuscaMetodoAtual(metodoPagamentoPortalRequest: $request)
            }
        `,
        variables: {
            request
        }
    })

    dispatchGraphError(errors);

    return pagamentoBuscaMetodoAtual;
}



export async function TrocarMetodoPagamento(request) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    const {
        data: {
            pagamentoTrocarMetodo
        },
        errors
    } = await client.mutate({
        mutation: gql`
            mutation PagamentoTrocarMetodo($request: MetodoPagamentoPortalRequest) {
                pagamentoTrocarMetodo(request: $request) {
                    value {
                        matriculaId
                        responsavelFinanceiro
                        responsavelFinanceiroToken
                        metodoPagamento
                        debitoAutomaticoBanco
                        debitoAutomaticoAgencia
                        debitoAutomaticoConta
                        tipoMetodo
                        creditoRecorrenteToken
                        creditoRecorrenteBandeira
                        creditoRecorrenteCartao
                        posted
                        dataAreaId
                        errosList {
                            descricao
                        }
                    }
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            request
        }
    })

    dispatchGraphError(errors);

    return pagamentoTrocarMetodo;
}

export function QntDigitoConta(numBanco) {
    var result = 0;

    switch (numBanco) {
        case "237":
            result = 7
            break;
        case "001":
            result = 14
            break;
        case "033":
            result = 13
            break;
        case "104":
            result = 8
            break;
        case "341":
            result = 8
            break;
    }

    return result
}