import React, {useEffect, useState} from "react";
import withUnidade from "../../hocs/withUnidade";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import {Grid, Grow, MenuItem, Skeleton} from "@mui/material";
import TextField from "@mui/material/TextField";
import {useFormik} from "formik";
import * as _guardianService from "../../service/guardian-service";
import csatList from "../../utils/csatList";
import {Unidade} from "src/components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import * as Yup from "yup";
import AnexoFoto from "../../components/AnexoFoto/AnexoFoto";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import SaveIcon from "@mui/icons-material/Save";
import {CloseTwoTone, ImageTwoTone} from "@mui/icons-material";
import {ExibeMensagem} from "../../components/shared/Message";
import {styled} from "@mui/system";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import DialogContent from "@mui/material/DialogContent";

const StyleDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
}));

type ControllerGroup = {
  id: string
  uId: string
  name: string
}

const validationSchema = Yup.object({
  name: Yup.string().nullable(),
  photo: Yup.string().nullable(),
  photoURL: Yup.string().nullable(),
  groupId: Yup.string().nullable()
})

const initialValues = {
  name: '',
  photo: '',
  photoURL: '',
  groupId: ''
};

const Publicidade: React.FC<{
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}> = (
  {
    Unidade,
    handleShowCsat
  }
) => {
  const [loading, setLoading] = useState(true);
  const [unitAds, setUnitAds] = useState([]);
  const [controllerGroupsOptions, setControllerGroupsOptions] = useState<Array<ControllerGroup> | undefined>(undefined);
  const [saving, setSaving] = useState(false);
  const [publicURL, setPublicURL] = useState("");
  const [showInfo, setShowInfo] = useState(false);
  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers): Promise<void> => {
      try {
        if (saving) return;

        setSaving(true);
        
        const resp = await _guardianService.publishAd({
          ...values,
          unitId: Unidade?.id
        });
        
        setSaving(false);
        if (resp) {
          ExibeMensagem("Publicidade cadastrada com sucesso", "0", true);
        } else {
          ExibeMensagem("Erro ao cadastrar a publicidade", "2", true);
        }

      } catch (err) {
        console.error(err);
        setSaving(false);
        ExibeMensagem("Erro ao cadastrar a publicidade", "2", true);
      } finally {
        if (handleShowCsat) handleShowCsat(csatList.alteracaoCatracaContato)
      }
    }
  });

  useEffect(() => {
    Promise.all([
      _guardianService.getAdByUnit(Unidade.id).then(resp => {
        setUnitAds(resp);
      }),
      _guardianService.crmUnitControllerGroups({
        unitCrmId: Unidade?.id
      }).then(({data}) => {
        setControllerGroupsOptions(data)
      })
    ]).finally(() => setLoading(false));
    _guardianService.crmUnitControllerGroups({
      unitCrmId: Unidade?.id
    }).then(({data}) => {
      setControllerGroupsOptions(data)
    })
  }, [Unidade]);
  
  const handleChangeGroup = event => {
    formik.handleChange(event);
    if (unitAds?.length) {
      const unitAd = unitAds.find(u => u?.idPortaria === event.target.value)
      
      if (unitAd) {
        setPublicURL(unitAd?.publicURL)
        formik.setFieldValue("name", unitAd?.nome)
        formik.setFieldValue("photoURL", unitAd?.imagemUrl)
      } else {
        formik.resetForm();
        formik.setFieldValue("groupId", event.target.value)
        setPublicURL("");
      }
    }
  }
  
  if (loading) {
    return (
      <>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
          }}
        >
          <Stack spacing={3}>
            <Stack
              spacing={4}
              useFlexGap
              flexWrap="wrap"
            >
              <Skeleton
                animation="wave"
                width="200px"
                height="40px"
                sx={{
                  transform: 'none',
                }}
              />
              
              <Grid
                container
                spacing={4}
                width="100%"
                justifyContent="center"
                sx={{
                  mt: 4
                }}
              >
                <Grid
                  container
                  md={6}
                  width="100%"
                  justifyContent="center"
                >
                  <Grid
                    md={6}
                  >
                    <Skeleton
                      animation="wave"
                      width="200px"
                      height="300px"
                      sx={{
                        transform: 'none',
                      }}
                    />
                  </Grid>
                  
                  <Grid
                    md={6}
                    width="100%"
                  >
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        width="100%"
                        height="40px"
                        sx={{
                          transform: 'none',
                        }}
                      />
                    </Stack>
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        width="100%"
                        height="40px"
                        sx={{
                          transform: 'none',
                        }}
                      />
                    </Stack>
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <Skeleton
                        animation="wave"
                        width="100%"
                        height="40px"
                        sx={{
                          transform: 'none',
                        }}
                      />
                    </Stack>
                  </Grid>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </Box>
      </>
    )
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <form
          onSubmit={formik.handleSubmit}
        >
          <Stack
            spacing={3}
            sx={{mb: 3}}
          >
            <div>
              <Typography
                variant="h4"
                sx={{
                  mb: 3
                }}
              >
                Publicidade
              </Typography>
            </div>
          </Stack>

          <Stack spacing={3}>
            <Stack
              spacing={4}
              useFlexGap
              flexWrap="wrap"
            >
              <Typography variant="h6">
                Informações do anúncio
              </Typography>

              <Grid
                container
                spacing={4}
                width="100%"
                justifyContent="center"
                sx={{
                  mt: 4
                }}
              >
                <Grid
                  container
                  md={6}
                  width="100%"
                  justifyContent="center"
                >
                  <Grow
                    in
                    style={{transformOrigin: '0 0 0'}}
                    {...{timeout: 1000}}
                  >
                    <Grid
                      md={6}
                    >
                      <AnexoFoto
                        title="Imagem"
                        disabled={saving}
                        width={200}
                        height={300}
                        value={{
                          publicURL: formik.values.photo.length ? formik.values.photo : publicURL
                        }}
                        onUpload={(_, base64) => {
                          formik.setFieldValue('photo', base64)
                        }}
                        noImageIcon={<ImageTwoTone/>}
                        infoContent="Clique aqui para mais informações..."
                        onClickInfo={() => setShowInfo(true)}
                      />
                      
                      <StyleDialog
                        fullWidth
                        maxWidth="lg"
                        onClose={() => setShowInfo(false)}
                        open={showInfo}
                      >
                        <Stack
                          alignItems="center"
                          direction="row"
                          justifyContent="space-between"
                          spacing={3}
                          sx={{
                            px: 3,
                            py: 2
                          }}
                        >
                          <Typography variant="h6">
                            Informações para envio de publicidade
                          </Typography>
                          <IconButton
                            color="inherit"
                            onClick={() => setShowInfo(false)}
                            sx={{
                              width: "auto",
                            }}
                          >
                            <CloseTwoTone/>
                          </IconButton>
                        </Stack>
                        <DialogContent>
                          <Box>
                            <iframe
                              src="/pdf/SEB%20Guardian%20-%20Advertisements.pdf"
                              width="100%"
                              height="600px"
                              frameBorder="0"
                            ></iframe>
                          </Box>
                        </DialogContent>
                      </StyleDialog>
                    </Grid>
                  </Grow>
                </Grid>

                <Grow
                  in
                  easing="ease-out"
                  mountOnEnter
                  unmountOnExit
                  timeout={800}
                >
                  <Grid
                    md={6}
                    width="100%"
                  >
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <TextField
                        error={!!(formik.touched.groupId && formik.errors.groupId)}
                        fullWidth
                        helperText={String((formik.touched.groupId && formik.errors.groupId) ?? "")}
                        label="Controladores de destino"
                        name="groupId"
                        size="small"
                        select
                        onBlur={formik.handleBlur}
                        onChange={handleChangeGroup}
                        value={formik.values.groupId}
                      >
                        {controllerGroupsOptions?.map(group => (
                          <MenuItem
                            key={group?.uId}
                            value={group?.uId}
                          >
                            {group?.name}
                          </MenuItem>
                        ))}
                      </TextField>
                    </Stack>
                    
                    <Stack
                      direction={{md: 'row', sm: 'column'}}
                      spacing={3}
                      sx={{
                        mb: 3
                      }}
                    >
                      <TextField
                        error={!!(formik.touched.name && formik.errors.name)}
                        fullWidth
                        helperText={String((formik.touched.name && formik.errors.name) ?? "")}
                        label="Nome"
                        name="name"
                        size="small"
                        onBlur={formik.handleBlur}
                        onChange={formik.handleChange}
                        value={formik.values.name}
                      />
                    </Stack>
                  </Grid>
                </Grow>
              </Grid>

              <Grid
                container
                width="100%"
                justifyContent="flex-end"
              >
                <Grid
                  md={3}
                  sx={{
                    mt: 1
                  }}
                  width="100%"
                  justifyContent="center"
                >
                  <Grow
                    in
                    easing="ease-out"
                  >
                    <Button
                      color="primary"
                      variant="contained"
                      type="submit"
                      startIcon={saving ? <CircularProgress color="primary" size={20}/> : <SaveIcon/>}
                      disabled={saving || !formik.values.photo.length}
                    >
                      Salvar
                    </Button>
                  </Grow>
                </Grid>
              </Grid>
            </Stack>
          </Stack>
        </form>
      </Box>
    </React.Fragment>
)
}

export default withUnidade(Publicidade, "Publicidade")