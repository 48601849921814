import { useEffect, useState } from 'react';
import Badge from 'react-bootstrap/Badge';
import { getAmbientes } from '../../service/ambiente-service';

import './styles.css';

function Ambientes() {
    const [crmSistema, setCrmSistema] = useState("");
    const [erpSistema, setErpSistema] = useState("");
    const [coreSistema, setCoreSistema] = useState("");
    const [graphSistema, setGraphSistema] = useState("");
    const [ambiente, setAmbiente] = useState("");


    useEffect( () => {
        setGraphSistema(process.env.REACT_APP_API_URL)
        if (window.location.href.includes('localhost')) {
            setAmbiente('LOCALHOST');
        } else if (window.location.href.includes('dev.secretariadigital.seb') || window.location.href.includes('dev.secretariav2.seb.com.br')) {
            setAmbiente('DESENVOLVIMENTO');
        } else if (window.location.href.includes('hmg') || window.location.href.includes('hml.secretariav2.seb.com.br')) {
            setAmbiente('HOMOLOGAÇÃO');
        } else if (window.location.href.includes('app-secretaria.azurewebsites')) {
            setAmbiente('PRÉ-PRODUÇÃO');
        } else if (window.location.href.includes('prod') || window.location.href.includes('secretariadigital.sebsa') || window.location.href.includes('secretariadigital.seb') || window.location.href.includes('secv2.seb')) {
            setAmbiente('PRODUÇÃO');
        }
        getAmbientes().then((ambientes) => {
            if (ambientes) {
                setCrmSistema(ambientes.crmUrl);
                setErpSistema(ambientes.erpUrl);
                setCoreSistema(ambientes.coreUrl);
            }
        });
    }, []);

    return (
        <>
            <div className='tooltip-container'>
                <h6
                    className="sideBarSecretaria-Ambientes"
                >
                    {ambiente === 'HOMOLOGAÇÃO' || ambiente === 'LOCALHOST' || ambiente === 'DESENVOLVIMENTO' || ambiente === 'PRÉ-PRODUÇÃO' ?
                        <Badge pill bg="warning" text="dark"> {ambiente} </Badge>
                        : <Badge pill bg="light" text="dark"> {ambiente} </Badge>
                    }
                    <span className="tooltip-text">
                        CRM<br /> {crmSistema}<br />
                        ERP<br /> {erpSistema}<br />
                        CORE<br /> {coreSistema}<br />
                        GRAPH<br /> {graphSistema}
                    </span>
                </h6>
            </div>
        </>
    );
}

export default Ambientes;