import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import {CloseTwoTone} from "@mui/icons-material";
import MatriculaAutocompleteSelect from "../MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import {styled} from "@mui/system";
import UnidadeAutocompleteSelect from "../UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";

const StyleDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiPaper-root": {
    overflowY: "visible",
  },
}));

export const SearchDialog = (props) => {
  const { onClose, open = false, ...other } = props;

  return (
    <StyleDialog
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      open={open}
      {...other}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        spacing={3}
        sx={{
          px: 3,
          py: 2
        }}
      >
        <Typography variant="h6">
          Selecione a unidade e matrícula
        </Typography>
        <IconButton
          color="inherit"
          onClick={onClose}
          sx={{
            width: "auto",
          }}
        >
          <CloseTwoTone/>
        </IconButton>
      </Stack>
      <DialogContent>
        <Box>
          <UnidadeAutocompleteSelect/>
          <MatriculaAutocompleteSelect
            maxWidth={520}
          />
        </Box>
      </DialogContent>
    </StyleDialog>
  );
};

SearchDialog.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool
};
