import React, {Component} from 'react';
import * as _matriculaService from '../../service/matricula-service.js';
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import * as _cursoService from '../../service/curso-service.js';
import * as _turmaService from "../../service/turma-service.js";
import * as formValidacao from "../../Helpers/form-validacao.js";
import * as Message from "../../components/shared/Message";
import {Nav, NavDropdown,} from 'react-bootstrap';
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import AlertBloqueio from "../../components/AlertBloqueio/AlertBloqueio";
import csatList from "../../utils/csatList";

class TrocaCurso extends Component {
  static displayName = TrocaCurso.name;

  constructor(props) {
    super(props);
    this.state = this.getState()
  }

  getState() {
      return {
          isLoading: false,
          isLoadingBtn: false,
          errors: {},
          error: "",
          Matricula: this.props.Matricula,
          DadosMatricula: {},
          ServicosUnidade: [],
          Turmas: [],
          Selects: {
              curso: "",
              turma: ""
          },
          nomeCurso: "",
          nomeTurma: "",
          mostraTela: true,
          msgOk: "",
          permiteMovimentarAvista: true
      }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.Matricula !== this.props.Matricula) {
          this.setState(this.getState(), () => {
              this.componentDidMount()
          })
      }
  }

    async componentDidMount() {
    this.setState({isLoading: true});
    const tipoMovimentacao = 284360002;

    var resultMatricula = await _movimentacoesService.GetByIdMatriculaMovimentacao(this.state.Matricula.idMatricula, tipoMovimentacao);
    var unidadeId = sessionStorage.getItem("@SECRETARIA/idUnidade");
    var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.Matricula.idMatricula, unidadeId, tipoMovimentacao, 0);
    if (resultMatricula && resultMatricula.value != null) {
      if (resultMatricula.value.tipoMatricula_Value === 936190001) {
        Message.ExibeMensagem("Matrícula já movimentada ao menos uma vez");
        this.setState({isLoading: false});
      } else {
        var dadosMatricula = {};
        var servicosUnidade = [];

        dadosMatricula = resultMatricula.value;
        var servicosUnidadeResult = await _cursoService.ListByIdUnidade(dadosMatricula.unidade_Id, this.state.Matricula.idMatricula, 284360000);
        if (servicosUnidadeResult != null && !('messages' in servicosUnidadeResult)) {
          servicosUnidade = servicosUnidadeResult.filter((item) => item.id !== dadosMatricula.servico_Id);
        } else if (servicosUnidadeResult && 'messages' in servicosUnidadeResult) {
          Message.ExibeListMensagem(servicosUnidadeResult.messages)
          this.setState({isLoading: false, error: servicosUnidadeResult.messages[0].text});
        }

        this.setState({
          isLoading: false,
          DadosMatricula: resultMatricula.value,
          ServicosUnidade: servicosUnidade,
          permiteMovimentarAvista: permiteMovimentarAvista.value
        });
      }
    } else if (!resultMatricula || resultMatricula.statusCode !== 0 || resultMatricula.statusCode === undefined) {
      Message.ExibeListMensagem(resultMatricula.messages)
      this.setState({isLoading: false, error: resultMatricula.messages[0].text});
    }
  }

  async OnChange(event) {
    var campos = this.state.Selects;
    campos[event.target.name] = event.target.value;

    if (event.target.name == "curso") {
      var resultTurmas = await _turmaService.TurmasServicoUnidade(event.target.value);
      var turmas = [];
      if (resultTurmas != null && resultTurmas != undefined) {
        turmas = resultTurmas;
      }
      this.setState({Turmas: turmas});
    }

    this.setState({Selects: campos});
  }

  FormValidation() {
    let errors = {};
    let formIsValid = true;
    let inputs = this.state.Selects;

    if (!formValidacao.isValidComboBox(inputs.curso)) {
      errors["curso"] = "Campo obrigatório.";
      formIsValid = false;
    } else {
      errors["curso"] = "";
      formIsValid = true;
    }
    if (!formValidacao.isValidComboBox(inputs.turma)) {
      errors["turma"] = "Campo obrigatório.";
      formIsValid = false;
    } else {
      errors["turma"] = "";
      formIsValid = true;
    }
    this.setState({errors: errors});
    return formIsValid;
  }

  async Salvar(event) {
    event.preventDefault();
    try {
        if (!this.FormValidation()) {
            return false;
        }
        this.setState({isLoadingBtn: true});
        var result = await _matriculaService.TrocarCursoNovaMatricula(this.state.Matricula.idMatricula, this.state.Selects.curso, this.state.Selects.turma);
        this.setState({isLoadingBtn: false});
        if (result != null && result != undefined && result.value != undefined && result.value.id != undefined) {
            Message.ExibeMensagem("Novo curso criado!", "0", true);

            this.setState({mostraTela: false, msgOk: "Alteração realizada com sucesso."});

        } else if (result != null && result != undefined && result.value != undefined) {
            Message.ExibeMensagem(result.value, "2", true);
        } else {
            Message.ExibeMensagem("Erro ao criar novo curso!", "2", true);
        }
    } catch (e) {
        console.error(e)
    } finally {
        if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.trocaDeCurso)
    }
  }

  async SelecioneCurso(curso) {
    var campos = this.state.Selects;
    campos["curso"] = curso.id;

    if (curso.id != null && curso.id != undefined) {
      var resultTurmas = await _turmaService.TurmasServicoUnidade(curso.id);
      var turmas = [];
      if (resultTurmas != null && resultTurmas != undefined) {
        turmas = resultTurmas;
      }
      this.setState({Turmas: turmas});
    }

    this.setState({Selects: campos});
    this.setState({nomeCurso: curso.nome});
  }

  async SelecioneTurma(turma) {
    var campos = this.state.Selects;
    campos["turma"] = turma.id;

    this.setState({Selects: campos});
    this.setState({nomeTurma: turma.nome + " - Período: " + turma.periodo});
  }


  render() {
    const {
      isLoading,
      DadosMatricula,
      Matricula,
      isLoadingBtn,
      ServicosUnidade,
      Selects,
      Turmas,
      nomeCurso,
      nomeTurma,
      mostraTela,
      msgOk,
      error,
      permiteMovimentarAvista
    } = this.state;
    
    if (error.length) {
      return (
        <AlertBloqueio
          mensagemBloqueio={error}
        />
      )
    }

    {
      if (!permiteMovimentarAvista) {
        return (
          <div>
            <div className=" form-group d-flex justify-content-center">
              <h3 style={{color: '#0068b2'}}><strong>AVISO</strong></h3>
            </div>
            <div className=" form-group d-flex justify-content-center">
              <h5 style={{color: '#0068b2'}}> O Template selecionado não permite movimentações onde o pagamento é
                Avista.</h5>
            </div>
          </div>
        );
      }
    }

    if (isLoading) {
      return <LoadingAnimation/>
    } else {
      return (
        <div>
          <div className="row">
            <div className="col-12">
              <div className="step-1">
                <h1 className="modal-title">Aluno(a): {Matricula?.estudante_Name}</h1>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="step-1">
                <h5>Curso atual: <b>{DadosMatricula?.servico_Name}</b>.</h5>
              </div>
            </div>
            <h2 style={{"color": 'blue'}}>{msgOk}</h2>
          </div>
          <div style={{"display": mostraTela ? 'block' : 'none'}}>
            <form onSubmit={(e) => this.Salvar(e)}>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label>Curso</label>
                    <Nav className='me-auto'>
                      <NavDropdown className={"Fundounidade ddlFundoBranco form-control " +
                        formValidacao.cssComboBox(
                          this.state.errors["curso"],
                          Selects?.curso
                        )}
                                   title={(nomeCurso != null && nomeCurso != undefined && nomeCurso != "" ? nomeCurso : "Selecione o curso...")}
                                   id='collasible-nav-dropdown'>
                        {ServicosUnidade?.map((item, idx) => (

                          <NavDropdown.Item key={idx} className="cssScroll" onClick={() => this.SelecioneCurso(item)}
                                            href='#'>
                            {item.nomeLandingPage}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </Nav>
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label>Turma</label>
                    <Nav className='me-auto'>
                      <NavDropdown className={"Fundounidade ddlFundoBranco form-control " +
                        formValidacao.cssComboBox(
                          this.state.errors["turma"],
                          Selects?.curso
                        )}
                                   title={(nomeTurma != null && nomeTurma != undefined && nomeTurma != "" ? nomeTurma : "Selecione a turma...")}
                                   id='collasible-nav-dropdown'>
                        {Turmas?.map((turma, idx) => (

                          <NavDropdown.Item key={idx} className="cssScroll" onClick={() => this.SelecioneTurma(turma)}
                                            href='#'>
                            {turma.nome + " - Período: " + turma.periodo}
                          </NavDropdown.Item>
                        ))}
                      </NavDropdown>
                    </Nav>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-3">
                </div>
                <div className="col-6">
                  <button type="submit" className="btn btn-primary mt-2" variant="sucess"
                          disabled={isLoadingBtn ? "disabled" : ""}>{isLoadingBtn ? "Salvando..." : "Salvar"}</button>
                </div>
                <div className="col-3">
                </div>
              </div>
            </form>
          </div>
        </div>
      )
    }
  }
}

export default withUnidadeMatricula(TrocaCurso, "Troca de Curso", csatList.trocaDeCurso);