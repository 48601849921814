

export function ConvertUrlToBlob(blobUrl_) {
    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest;
        xhr.onload = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    var reader = new FileReader();
                    reader.onloadend = function () {
                        resolve(reader.result);
                    }
                    reader.readAsDataURL(xhr.response);
                }
                else
                    reject(xhr.statusText);
            }
        };
        xhr.responseType = 'blob';
        xhr.open('GET', blobUrl_);
        xhr.send();
    });
}

export function ConvertBlobUrlToBase64(blobUrl_) {

    return new Promise((resolve, reject) => {
        let xhr = new XMLHttpRequest;
        xhr.responseType = 'blob';

        xhr.onload = function () {
            if (xhr.readyState === 4) {
                if (xhr.status === 200) {
                    let recoveredBlob = xhr.response;
                    const reader = new FileReader();
                    reader.addEventListener('loadend', (e) => {
                        resolve(
                            //btoa(String.fromCharCode(...new Uint8Array(e.target.result)))

                            btoa(new Uint8Array(e.target.result).reduce(function (data, byte) {
                                return data + String.fromCharCode(byte);
                            }, ''))
                        );
                    });
                    reader.readAsArrayBuffer(recoveredBlob);
                }
                else
                    reject(xhr.statusText);
            }
        };
        xhr.open('GET', blobUrl_);
        xhr.send();
    });
}