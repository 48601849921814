import {gql} from "@apollo/client";
import {dispatchGraphError} from "../utils/dispatchGraphError";

export async function GetList(sistemaId) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.query({
        query :  gql`
            query GetBlackListBySistemaId($sistemaId: ID!) {
                getBlackListBySistemaId(sistemaId: $sistemaId) {
                    id
                    sistemaId
                    action
                    matriculaId
                    type
                    unidadeId
                    mensagemBloqueio
                    codigoMatriculaCrm
                }
            }
        `,
        variables: {
            sistemaId
        }
    }).then(data => {
        const {
            data: {
                getBlackListBySistemaId
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return getBlackListBySistemaId;
    }).catch(console.error)
}

export async function GetAll() {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.query({
        query :  gql`
            query GetAll {
                getBlackListAll {
                    id
                    sistemaId
                    action
                    matriculaId
                    type
                    unidadeId
                    mensagemBloqueio
                    codigoMatriculaCrm
                }
            }
        `
    }).then(data => {
        const {
            data: {
                getBlackListAll
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return getBlackListAll;
    }).catch(console.error)
}

export async function Create(blackList) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation :  gql`
            mutation CriarBlackList($blackList: BlackListRequest) {
                criarBlackList(blackList: $blackList) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            blackList
        }
    }).then(data => {
        const {
            data: {
                criarBlackList
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return criarBlackList;
    }).catch(console.error)
}

export async function Delete(resourceId) {
    /** @type ApolloClient<NormalizedCacheObject> */
    const client = window.apolloClient;
    return client.mutate({
        mutation :  gql`
            mutation DeletarBlackListByFilter($filter: BlackListFilterInput!) {
                deletarBlackListByFilter(filter: $filter) {
                    value
                    messages {
                        text
                        typeMessage
                    }
                    statusCode
                }
            }
        `,
        variables: {
            filter: {
                id: resourceId
            }
        }
    }).then(data => {
        const {
            data: {
                deletarBlackListByFilter
            },
            errors
        } = data;

        dispatchGraphError(errors);

        return deletarBlackListByFilter;
    }).catch(console.error)
}
