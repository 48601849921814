import React, { Component } from "react";
import * as Message from '../shared/Message';
import * as _integracaoMatriculaService from '../../service/integracaoMatricula-service';
import {ReplayParcialUpSelling} from "../../service/integracaoMatricula-service";

export class ReplayIntegracaoUpSellingParcial extends Component {
    static displayName = ReplayIntegracaoUpSellingParcial.name;

    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            matriculaCrm: "",
            qntParcela: "",
            dataReferencia:"",
            result: {},
            request: {},
            selectedFile: null,
        };
    }

    async componentDidMount() {


    }

    changeCheckBox(event) {
        
        var request = this.state.request;

        if (event.target.name == "ficha") {
            if (event.target.checked) {
                request.enviarFichaFinanceira = true;
            } else {
                request.enviarFichaFinanceira = false;
            }
        }

        if (event.target.name == "transacao") {
            if (event.target.checked) {
                request.enviarTransacaoFinanceira = true;
            } else {
                request.enviarTransacaoFinanceira = false;
            }
        }

        if (event.target.name == "servicomatricula") {
            if (event.target.checked) {
                request.enviarServicoMatricula = true;
            } else {
                request.enviarServicoMatricula = false;
            }
        }

        if (event.target.name == "ov") {
            if (event.target.checked) {
                request.enviarOrdemVenda = true;
            } else {
                request.enviarOrdemVenda = false;
            }
        }

        this.setState({ request: request });
    }

    change(event) {
        
        if (event.target.name == "qntParcela") {
            this.setState({ qntParcela: event.target.value });
        }
        else if (event.target.name == "dataReferencia")
        {
            this.setState({ dataReferencia: event.target.value });
        }
        else {
            this.setState({ matriculaCrm: event.target.value });
        }
    }

    handleSubmit = async (e) => {
        e.preventDefault();
        this.setState({ result: {} })

        this.setState({ isLoading: true });

        const result = await _integracaoMatriculaService.ReplayParcialUpSelling({
            codigoMatricula: this.state.matriculaCrm,
            qntParcela: parseInt(this.state.qntParcela),
            dataReferencia: this.state.dataReferencia,
            envioItems: this.state.request,
        });

        this.setState({
            isLoading: false,
            result,
            request: {},
        });

        if (this.state.result != null) {
            Message.ExibeListMensagem(this.state.result.messages);
        } else {
            Message.ExibeMensagem("Não foi possível obter retorno da rotina, verifique se código ERP foi criado na matrícula", "2");
        }
    };

    render() {
        const { base64 } = this.state;
        if (this.state.isLoading) {
            return (
                <div className="panel">
                    <h2 className="tituloCss">Replay Integração Parcial de UpSelling</h2>
                    <div className="panel-body p-25">
                        <h4>Loading... <img width="25" height="25" alt="icone de loading" src="img/loading.gif" /></h4>
                    </div>
                </div>
            );

        } else {
            return (
                <div className="panel">
                    <h2 className="tituloCss">Integração Total de UpSelling</h2>
                    <div className="panel-body p-25 mb-3">
                        <div className="col-md-12 col-lg-12">

                            <form onSubmit={this.handleSubmit} asp-controller="CheckOut" asp-action="ReplayIntegracaoUpSellingTotal" >
                                
                                <div className="row">
                                    <p>
                                        <strong>Código Matrícula UpSelling</strong>
                                    </p>
                                    <div className="col-md-2 col-lg-2">
                                        <input type="text" className={"txtBorder form-control "} name={"matriculaCrm"} onChange={event => this.change(event)} />
                                    </div>

                                    <div className="col-md-2 col-lg-2">
                                        <input type="date" className={"txtBorder form-control "} name={"dataReferencia"} onChange={event => this.change(event)} />
                                    </div>
                                </div>
                                <br />
                                <div className="row">
                                    <strong>Quantidade Parcela</strong>
                                    <div className="col-md-1 col-lg-1">
                                        <input type="number" className={"txtBorder form-control "} name={"qntParcela"} onChange={event => this.change(event)} />
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="ficha" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Ficha
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="transacao" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Transações
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="servicomatricula" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Serviço Matricula
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <label>
                                            <input type="checkbox" className="checkbox" name="ov" onChange={event => this.changeCheckBox(event)} />
                                            Enviar Ordem de Venda
                                        </label>
                                    </div>
                                    <div className="col-md-2 col-lg-2">
                                        <button className="btn btn-primary mb-3" type="submit">Enviar</button>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>
            );
        }
    }
}
