import React, {Component} from 'react';
import {Button} from "react-bootstrap";
import * as Message from '../../components/shared/Message';
import * as _metodopagamentoService from '../../service/metodopagamento-service';
import * as _movimentacoesService from '../../service/movimentacoes-service.js';
import * as _blackListService from "../../service/blacklist-service";
import * as formValidacao from "../../Helpers/form-validacao.js";
import _banks from "../../Helpers/data/banks.json";
import _bankscarol from "../../Helpers/data/bankscarol.json";
import {PersonFill} from 'react-bootstrap-icons';
import InputMask from "react-input-mask";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import blockedResourcesSystem from "../../utils/blockedResourcesSystem";
import {blockedResourcesEntitiesValues} from "../../utils/blockedResourcesEntities";
import csatList from "../../utils/csatList";
import Swal from 'sweetalert2';
import moment from 'moment-timezone';

class TrocaMetodoPagamento extends Component {
    static displayName = TrocaMetodoPagamento.name;

    constructor(props) {
        super(props);
        this.state = this.getState()

    }

    getState() {
        return {
            QntDigitoConta: 0,
            exibeModal: false,
            isLoadingConfirmacao: false,
            isLoadingCarregamento: false,
            isLoadingMetodo: false,
            metodoAlterado: false,
            boletoLock: false,
            daLock: false,
            isLoading: false,
            errors: {},
            Matricula: this.props.Matricula,
            responsavel: {},
            metodoAtual: "",
            opcaoSelecionada: "",
            dataAreaId: this.props.Unidade.dataAreaId,
            visibleDadosBanco: false,
            bancoSelecionado: "",
            camposBanco: {
                agencia: "",
                agenciabradesco: "",
                contacorrente: "",
                digitoConta: ""
            },
            responsaveis: [],
            responsavelSelecionado: "",
            permiteMovimentarAvista: true
        };
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevProps.Matricula !== this.props.Matricula) {
            this.setState(this.getState(), () => {
                this.componentDidMount()
            })
        }
    }

    async componentDidMount() {
        this.setState({ isLoading: true });
    
        var request = {
            DataAreaId: this.props.Unidade.dataAreaId,
            MatriculaId: this.props.Matricula?.codigoMatriculaErp,
            TipoResponsavelMatricula: "1"
        }
        
        this.verificaBloqueioMovimentacao();
        
        var responsaveis = await _metodopagamentoService.BuscaResponsaveisMatricula(request);

        var idUnidade = this.props.Unidade.id;
        var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.Matricula.idMatricula, idUnidade, 284360005, 0);
        this.setState({ isLoading: false, responsaveis: responsaveis, permiteMovimentarAvista: permiteMovimentarAvista.value });
    }
    
    verificaBloqueioMovimentacao = async () => {
        try {
            let blockedResources = await _blackListService.GetList(blockedResourcesSystem.Secretaria);
            if (blockedResources && blockedResources.length > 0) {
                let movimentacaoBoletoLock = blockedResources.find(x =>
                  x.type === blockedResourcesEntitiesValues.Unidade
                  && x.unidadeId === this.props.Unidade.id
                  && x.action === "Movimentacao/Unidade/Boleto"
                );

                if (movimentacaoBoletoLock ) {
                    this.setState({
                        boletoLock: true
                    })
                }

                let movimentacaoDALock = blockedResources.find(x =>
                  x.type === blockedResourcesEntitiesValues.Unidade
                  && x.unidadeId === this.props.Unidade.id
                  && x.action === "Movimentacao/Unidade/DA"
                );

                if (movimentacaoDALock ) {
                    this.setState({
                        daLock: true
                    })
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    OnChange(event) {
        var campos = this.state.camposBanco;
        if (event.target.name == "contacorrente") {
            campos[event.target.name] = event.target.value.replace(/[^0-9]/g, "");
        }
        else {
            campos[event.target.name] = event.target.value;
        }
        this.setState({ campos: campos });
    }

    FormValidation() {
        let errors = {};
        let formIsValid = true;
        let inputs = this.state.camposBanco;
        if (
            !formValidacao.isValidTexto(inputs.agencia.replace("_", ""), 4) &&
            !formValidacao.isValidTexto(inputs.agenciabradesco.replace("_", ""), 6) &&
            !formValidacao.isValidTexto(inputs.contacorrente, 3)
        ) {
            if (!formValidacao.isValidTexto(inputs.agencia, 4)) {
                errors["agencia"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["agencia"] = "";
                formIsValid = true;
            }
            if (!formValidacao.isValidTexto(inputs.agenciabradesco, 6)) {
                errors["agenciabradesco"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["agenciabradesco"] = "";
                formIsValid = true;
            }
            if (!formValidacao.isValidTexto(inputs.contacorrente, 3)) {
                errors["contacorrente"] = "Campo obrigatório.";
                formIsValid = false;
            } else {
                errors["contacorrente"] = "";
                formIsValid = true;
            }
        }
        this.setState({ errors: errors });
        return formIsValid;
    }

    alterarMetodoPagamento() {
        try {
            if (this.state.opcaoSelecionada == "") {
                Message.ExibeMensagem("Deve ser selecionado uma opção para a alteração.", 1, true);
                return;
            }

            if (this.state.responsavelSelecionado == "" || this.state.responsavelSelecionado == "0") {
                Message.ExibeMensagem("Deve ser selecionado um Responsável para a alteração.", 1, true);
                return;
            }

            console.log(this.state.responsavelSelecionado);

            var request = {};
            var agencia = "";
            var digitoAgencia = "";

            if (this.state.opcaoSelecionada != "Boleto_Bancario") {
                if (this.state.bancoSelecionado == "" || this.state.bancoSelecionado == "0") {
                    Message.ExibeMensagem("Deve ser selecionado um banco.", 1, true);
                    return;
                }

                if (this.state.bancoSelecionado == "237") {
                    if (this.state.camposBanco.agenciabradesco.replace("_", "").trim() == "") {
                        Message.ExibeMensagem("Deve ser digitado uma agência.", 1, true);
                        return;
                    }
                }
                else {
                    if (this.state.camposBanco.agencia.replace("_", "").trim() == "") {
                        Message.ExibeMensagem("Deve ser digitado uma agência.", 1, true);
                        return;
                    }
                }

                if (this.state.camposBanco.contacorrente.trim() == "") {
                    Message.ExibeMensagem("Deve ser digitado uma conta corrente.", 1, true);
                    return;
                }

                if (this.state.camposBanco.digitoConta.trim() == "") {
                    Message.ExibeMensagem("Deve ser digitado o dígito da conta corrente.", 1, true);
                    return;
                }

                if (this.state.bancoSelecionado == "237") {
                    if (this.state.camposBanco.agenciabradesco.replace("_", "").trim().length < 6) {
                        Message.ExibeMensagem("Agência inválida.", 1, true);
                        return;
                    }
                    var valorAgencia = this.state.camposBanco.agenciabradesco.replace("_", "").trim().split("-");
                    agencia = valorAgencia[0];
                    digitoAgencia = valorAgencia[1];
                }
                else {
                    if (this.state.camposBanco.agencia.replace("_", "").trim().length < 4) {
                        Message.ExibeMensagem("Agência inválida.", 1, true);
                        return;
                    }
                    agencia = this.state.camposBanco.agencia.replace("_", "").trim();
                    digitoAgencia = "";
                };

                request = {
                    DataAreaId: this.state.dataAreaId,
                    MatriculaId: this.props.Matricula?.codigoMatriculaErp,
                    CustAccount: this.state.responsavelSelecionado,
                    meioPagamento: 284360001,
                    DadosPagamentoDebitoAutomatico: {
                        debitoAutomaticoBanco: this.state.bancoSelecionado,
                        debitoAutomaticoAgencia: agencia,
                        debitoAutomaticoAgenciaDigito: digitoAgencia,
                        debitoAutomaticoConta: this.state.camposBanco.contacorrente.trim(),
                        debitoAutomaticoContaDigito: this.state.camposBanco.digitoConta.trim()
                    }
                };

            }
            else {
                request = {
                    DataAreaId: this.state.dataAreaId,
                    MatriculaId: this.props.Matricula?.codigoMatriculaErp,
                    CustAccount: this.state.responsavelSelecionado,
                    meioPagamento: 284360002
                };
            };
            this.setState({ isLoadingConfirmacao: true });
            var idUnidade = this.props.Unidade.id;
            _movimentacoesService.getTemplateMovimentacao(idUnidade, 284360005).then(({diaLimiteMes}) => {
              if (diaLimiteMes) {
                const diaAtual = parseInt(moment().tz("America/Sao_Paulo").format('DD/MM/YYYY').split("/")[0]);
                const passouDiaLimite = diaAtual >= diaLimiteMes ? true : false;
                if (passouDiaLimite){
                  Swal.fire({
                  title: 'A data limite para alteração de método de pagamento deste serviço é dia: ' + diaLimiteMes + ". A parcela para o próximo mês permanecerá a mesma(as demais serão ajustadas). Por favor, confirme se deseja prosseguir",
                  text: "Esta ação não poderá ser desfeita!",
                  icon: 'warning',
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  cancelButtonText: 'Cancelar',
                  confirmButtonText: 'Sim, Continuar!',
                  showLoaderOnConfirm: true,
                  allowOutsideClick: () => !Swal.isLoading(),
                  }).then(async (result) => {
                  if (result.isConfirmed) {
                    _metodopagamentoService.TrocarMetodoPagamento(request).then((result) => {
                      if (result == undefined) {
                        Message.ExibeMensagem("Erro ao processar a alteração de método.", '2', true);
                        this.setState({ isLoadingConfirmacao: false });
                        this.setState({ metodoAlterado: false });
                      } else {
                        if (result.statusCode != 0) {
                            Message.ExibeListMensagem(result.messages, true, "");
                            this.setState({ isLoadingConfirmacao: false });
                            this.setState({ metodoAlterado: false });
                        } else {
    
                            Message.ExibeMensagem("Alteração realizada com sucesso.", '0', true);
    
                            this.setState({ isLoadingConfirmacao: false });
                            this.setState({ metodoAlterado: true });
                        }
                      }
                      }).finally(() => {
                          if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.trocaMetodoPagamento)
                      });
                    }else{
                        this.setState({ isLoadingConfirmacao: false });
                        this.setState({ metodoAlterado: false });
                    }
                    })
                } else {
                    _metodopagamentoService.TrocarMetodoPagamento(request).then((result) => {
                      console.log(result)
                      if (result == undefined) {
                        Message.ExibeMensagem("Erro ao processar a alteração de método.", '2', true);
                        this.setState({ isLoadingConfirmacao: false });
                        this.setState({ metodoAlterado: false });
                      } else {
                        if (result.statusCode != 0) {
                          Message.ExibeListMensagem(result.messages, true, "");
                          this.setState({ isLoadingConfirmacao: false });
                          this.setState({ metodoAlterado: false });
                        } else {
                          Message.ExibeMensagem("Alteração realizada com sucesso.", '0', true);
  
                          this.setState({ isLoadingConfirmacao: false });
                          this.setState({ metodoAlterado: true });
                        }
                        }
                    }).finally(() => {
                        if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.trocaMetodoPagamento)
                    });
                }
              }
            })

        } catch (e) {
            console.error(e)
        }
    }

    onChangeValue(event) {
        this.setState({ opcaoSelecionada: event.target.value });
        this.setState({ bancoSelecionado: "" });

        if (event.target.value == "Boleto_Bancario") {
            var campos = this.state.camposBanco;
            campos["agencia"] = "";
            campos["agenciabradesco"] = "";
            campos["contacorrente"] = "";
            campos["digitoConta"] = "";
            this.setState({ visibleDadosBanco: false });
            this.setState({ campos: campos })
        }
        else {
            this.setState({ visibleDadosBanco: true });
        }
    }

    onChangeBanco(event) {
        var qntDigitoConta = _metodopagamentoService.QntDigitoConta(event.target.value);
        this.setState({ QntDigitoConta: qntDigitoConta });
        var campos = this.state.camposBanco;
        this.setState({ campos: campos })
    }

    onChangeDigito(event) {
        var item = this.state.camposBanco;

        var regex = /\d+/g;
        var result = "";
        var returnRegex = event.target.value.toString().match(regex);

        if (returnRegex != null && returnRegex != undefined && !(event.target.name === "digitoConta" && (event.target.value.toString() === "X" || event.target.value.toString() === "x"))) {
            result = returnRegex.join('');
        }
        if (item[event.target.name] != undefined && item[event.target.name] != null) {
            item[event.target.name] = result;
        }

        //Regra especial para contemplar o "X" como exceção para digito
        if (event.target.name === "digitoConta" && (event.target.value.toString() === "X" || event.target.value.toString() === "x")) {
            item[event.target.name] = event.target.value.toString();
        }

        this.setState({ camposBanco: item });
    }

    async OnChangeResponsavel(event) {
        this.setState({ isLoadingMetodo: true });

        var request = {
            DataAreaId: this.state.dataAreaId,
            MatriculaId: this.props.Matricula?.codigoMatriculaErp,
            CustAccount: event.target.value
        };
        console.log(request);
        var metodoAtual = await _metodopagamentoService.BuscaMetodoAtual(request);
        console.log(metodoAtual);
        this.setState({
            isLoading: false, metodoAtual: metodoAtual,
            visibleDadosBanco: false
        });
        if (document.getElementById("metodoBoleto"))
            document.getElementById("metodoBoleto").checked = false;
        if (document.getElementById("metodoDebito"))
            document.getElementById("metodoDebito").checked = false;
        var campos = this.state.camposBanco;
        campos["agencia"] = "";
        campos["agenciabradesco"] = "";
        campos["contacorrente"] = "";
        campos["digitoConta"] = "";
        this.setState({ campos: campos })
        this.setState({ isLoadingMetodo: false });
    }

    render() {
        if (!this.state.permiteMovimentarAvista) {
            return (
                <div >
                    <div className=" form-group d-flex justify-content-center">
                        <h3 style={{ color: '#0068b2' }}> <strong>AVISO</strong></h3>
                    </div>
                    <div className=" form-group d-flex justify-content-center">
                        <h5 style={{ color: '#0068b2' }}> O Template selecionado não permite movimentações onde o pagamento é Avista.</h5>
                    </div>
                </div>
            );
        }

        if (this.state.metodoAlterado) {
            return (
                <div >
                    <div className=" form-group d-flex justify-content-center">
                        <h3 style={{ color: '#0068b2' }}> <strong>MÉTODO DA MATRÍCULA {this.props.Matricula?.codigoMatricula} ALTERADO! </strong>
                        </h3>
                    </div>
                </div>
            );
        }

        if (this.state.isLoading) {
            return (<div className="form-group d-flex justify-content-center"><img width="50" height="50" src="img/loading.gif" /></div>);
        }
        else {
            return (
                <div className="row p-b-8">
                    <fieldset className='p-b-8 p-t-8'>
                        <legend className="pull-left width-full">Informações da Matrícula <PersonFill className='fa-icon iconeAzul' /> </legend>
                        <div className="row">
                            <div className="col-md-8 p-b-8">
                                <div className="form-group">
                                    <label>Nome</label>
                                    <input className="form-control" disabled value={this.props.Matricula?.estudante_Name} />
                                </div>
                            </div>
                            <div className="col-md-4 p-b-8">
                                <div className="form-group">
                                    <label>Matrícula</label>
                                    <input className="form-control" disabled value={this.props.Matricula?.codigoMatricula} />
                                </div>
                            </div>
                            <div className="row col-md-12">&nbsp;</div>
                            <div className="col-md-8 p-b-8">
                                <div className="form-group-select">
                                    <label>Responsável</label>
                                    <br />
                                    <select name="responsaveis" id="responsaveis"
                                        className="ddlFundoBrancoBanco form-group"
                                        value={this.state.responsavelSelecionado}
                                        onChange={(e) => {
                                            this.setState({ responsavelSelecionado: e.target.value });
                                            this.OnChangeResponsavel(e);
                                        }} >
                                        <option value="0">SELECIONE UM RESPONSÁVEL</option>
                                        {
                                            this.state.responsaveis.map((option, index) => (
                                                <option key={index} value={option.responsavel}>{option.responsavel + " - " + option.nomeResponsavel}</option>))
                                        }
                                    </select>
                                    {this.state.isLoadingMetodo ? <img width="25" height="25" src="img/loading.gif" /> : ''}

                                </div>
                            </div>

                            <div className="col-md-4 p-b-8">
                                <div className="form-group">
                                    <label>Método Atual do Responsável</label>
                                    <input className="form-control" disabled value={this.state.metodoAtual} />
                                </div>
                            </div>

                        </div>
                        <div className="row col-md-12">&nbsp;</div>
                        <div className={(this.state.metodoAtual.indexOf("parcelas abertas") > 0 || this.state.metodoAtual.indexOf("método não cadastrado") > 0 || this.state.metodoAtual=="") ? "invisible" : "visible"}>
                            <legend className="pull-left width-full">
                                Alteração de método pagamento
                            </legend>
                            <div className="row" onChange={(e) => this.onChangeValue(e)}>
                                {!this.state.boletoLock && (
                                  <div className="col-md-2 p-b-8">
                                      <div>
                                          <input 
                                            id="metodoBoleto" 
                                            type="radio" 
                                            value="Boleto_Bancario"
                                            name="metodo" 
                                          /> Boleto Bancário
                                      </div>
                                  </div>
                                )}
                                {!this.state.daLock && (
                                  <div className="col-md-2 p-b-8">
                                      <div>
                                          <input 
                                            id="metodoDebito" 
                                            type="radio" 
                                            value="Debito_Bancario" 
                                            name="metodo" 
                                          /> Débito Automático
                                      </div>
                                  </div>
                                )}
                            </div>
                            <div className={this.state.visibleDadosBanco ? "visible" : "invisible"}>
                                <div className="row fundoCinza">
                                    &nbsp;
                                </div>
                                <div className="row fundoCinza" >
                                    <div className="col-md-4 p-b-8">
                                        <div className="form-group">
                                            <label>Banco</label>&nbsp;
                                            <select name="bancos" id="bancos"
                                                className="ddlFundoBrancoBanco form-group"
                                                value={this.state.bancoSelecionado}
                                                onChange={(e) => {
                                                    this.setState({ bancoSelecionado: e.target.value });
                                                    this.onChangeBanco(e);
                                                }} >
                                                <option value="0">SELECIONE UM BANCO</option>
                                                {
                                                    (this.state.dataAreaId != "ECP" && this.state.dataAreaId != "ECR") ?
                                                        _banks.map((option, index) => (
                                                            <option key={index} value={option.value}>{option.text}</option>))
                                                        :
                                                        _bankscarol.map((option, index) => (
                                                            <option key={index} value={option.value}>{option.text}</option>))
                                                }
                                            </select>
                                        </div>
                                    </div>


                                </div>
                                <div className="row fundoCinza">
                                    <div className="col-md-2 p-b-8">
                                        <div className="form-group">
                                            {
                                                this.state.bancoSelecionado != "237" ?
                                                    <label>Agência</label>
                                                    :
                                                    <label>Agência com o dígito</label>
                                            }
                                            {
                                                this.state.bancoSelecionado != "237" ?
                                                    <InputMask name="agencia" className={
                                                        "txtBorderMetodo form-control " +
                                                        formValidacao.cssTexto(
                                                            this.state.errors["agencia"],
                                                            this.state.camposBanco.agencia.replace("_", ""),
                                                            4
                                                        )
                                                    }
                                                        value={this.state.camposBanco.agencia}
                                                        onChange={(e) => this.OnChange(e)}
                                                        mask="9999" />
                                                    :
                                                    <InputMask name="agenciabradesco" className={
                                                        "txtBorderMetodo form-control " +
                                                        formValidacao.cssTexto(
                                                            this.state.errors["agenciabradesco"],
                                                            this.state.camposBanco.agenciabradesco.replace("_", ""),
                                                            6
                                                        )
                                                    }
                                                        value={this.state.camposBanco.agenciabradesco}
                                                        onChange={(e) => this.OnChange(e)}
                                                        mask="9999-9" />
                                            }
                                        </div>



                                    </div>
                                    <div className="col-md-2 p-b-8">
                                        <div className="form-group">
                                            <label>Número da conta</label>
                                            <InputMask name="contacorrente" className={
                                                "txtBorderMetodo form-control " +
                                                formValidacao.cssTexto(
                                                    this.state.errors["contacorrente"],
                                                    this.state.camposBanco.contacorrente,
                                                )
                                            }
                                                value={this.state.camposBanco.contacorrente}
                                                onChange={(e) => this.OnChange(e)} />
                                        </div>
                                    </div>

                                    <div className="col-sm-2 pb-3">
                                        <label>Dígito</label>
                                        <input type="text" name="digitoConta"
                                            className={"txtBorderMetodo form-control " +
                                                formValidacao.cssTexto(
                                                    this.state.errors["digitoConta"],
                                                    this.state.camposBanco.digitoConta,
                                                    1
                                                )}
                                            autoComplete="off" maxLength="1"
                                            value={this.state.camposBanco.digitoConta}
                                            onChange={(e) => {
                                                this.onChangeDigito(e);
                                            }}
                                        />
                                    </div>

                                </div>
                                <div className="row fundoCinza">
                                    &nbsp;
                                </div>
                            </div>
                            <br />
                            <div className="col-md-4 p-b-8">
                                <div className="form-group">
                                    <Button
                                        size="lg"
                                        name="Confirmar"
                                        className="btn btn-primary"
                                        disabled={this.state.isLoadingConfirmacao}
                                        onClick={() => { this.alterarMetodoPagamento() }}
                                        style={{ width: "100%" }}>
                                        {this.state.isLoadingConfirmacao ? 'Alterando…  ' : 'Confirmar'}
                                        {this.state.isLoadingConfirmacao ? <img width="25" height="25" src="img/loading.gif" /> : ''}
                                    </Button>
                                </div>
                            </div>
                            <br />
                        </div>
                    </fieldset>
                </div>
            );
        }
    }
}

export default withUnidadeMatricula(TrocaMetodoPagamento, "Troca de Método de Pagamento", csatList.trocaMetodoPagamento);