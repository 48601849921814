import { AuthenticationContext, adalGetToken } from "react-adal";

//ID´s de Registro da Aplicação no Azure AD
const appId = "3d90cd04-7efd-4151-a764-201e5959e56e";
const tenantid = 'be041442-f3b6-4be1-8509-eca9c817528f';

export const adalConfig= {
  cacheLocation: "localStorage",
  clientId: appId,
  endpoints: {
    api: appId,
  },
  postLogoutRedirectUri: window.location.origin,
  tenant: tenantid,
};

class AdalContext {
  constructor() {
      this.authContext = new AuthenticationContext(adalConfig);
  }

  get AuthContext() {
      return this.authContext;
  }

  GetToken = () => adalGetToken(this.authContext, appId);

    LogOut = () => this.authContext.logOut();
}

export default AdalContext;

