import {useRecoilValue, useSetRecoilState} from "recoil";
import {selectedMatriculaState} from "../recoil/atoms/selectedMatriculaState";
import {selectedUnidadeState} from "../recoil/atoms/selectedUnidadeState";
import Card from "@mui/material/Card";
import React, {useEffect} from "react";
import {Helmet} from 'react-helmet';
import useCsat from "../hooks/use-csat";
import Csat from "../components/Csat/Csat";
import {permissionsState} from "../recoil/atoms/permissionsState";
import {matriculasState} from "../recoil/atoms/matriculasState";
import {loadingMatriculasState} from "../recoil/atoms/loadingMatriculasState";
import {selectedMatriculaFullState} from "../recoil/atoms/selectedMatriculaFullState";
import Stack from "@mui/material/Stack";
import {Alert} from "@mui/material";
import {showSelectMatriculasState} from "../recoil/atoms/showSelectMatriculasState";
import { showSelectColaboradorState } from "src/recoil/atoms/showSelectColaboradorState";
import {usePageView} from "../hooks/use-page-view";

const defaultOptions = {
  needMat: true,
  csatTitle: "O quanto você ficou satisfeito com: Movimentações na Secretaria Digital?"
}

export default function withUnidadeMatricula(Component, title = '', csatName = '', csatTitle = "O quanto você ficou satisfeito com: Movimentações na Secretaria Digital?", options = defaultOptions) {
  return function _hoc(props) {
    const {
      needMat = true
    } = options;
    const matriculas = useRecoilValue(matriculasState);
    const loadingMatriculas = useRecoilValue(loadingMatriculasState);
    const matricula = useRecoilValue(selectedMatriculaState);
    const matriculaFull = useRecoilValue(selectedMatriculaFullState);
    const unidade = useRecoilValue(selectedUnidadeState);
    const permissions = useRecoilValue(permissionsState);
    const setShowSelectmatriculas = useSetRecoilState(showSelectMatriculasState);
    const setShowSelectcolaboradores = useSetRecoilState(showSelectColaboradorState);
    const pageTitle = (title ? `${title} | ` : '') + `Secretaria Web`
    const {
        addAnsweredCsat,
        isCsatVisible,
        handleShowCsat
    } = useCsat(csatName);

    usePageView();

    useEffect(() => {
      setShowSelectmatriculas(true);
      setShowSelectcolaboradores(false);
    }, [])

    return (
        <>
            <Csat
                show={isCsatVisible}
                scoreTitle={csatTitle}
                onSubmit={() => addAnsweredCsat(csatName)}
                onClosed={() => addAnsweredCsat(csatName)}
            />

            <Card
                sx={{
                    m: 2,
                    position: 'relative',
                    minHeight: '600px',
                    padding: '20px',
                }}
            >
                <Helmet>
                    <title>{pageTitle}</title>
                </Helmet>
                {(needMat && !matricula) || !unidade ? (
                    <Stack
                      direction="column"
                      justifyContent="center"
                      alignItems="center"
                      sx={{
                        height: '100%'
                      }}
                    >
                      <Alert severity="warning">Selecione a unidade e matrícula</Alert>
                    </Stack>
                ) : (
                    <Component
                        {...props}
                        Matricula={matricula}
                        matriculas={matriculas}
                        loadingMatriculas={loadingMatriculas}
                        MatriculaFull={matriculaFull}
                        Unidade={unidade}
                        permissions={permissions}
                        handleShowCsat={handleShowCsat}
                    />
                )}
            </Card>
        </>
    );
  }
}
