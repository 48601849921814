import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {Matricula} from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";

export const matriculasState = atom<Matricula[]>({
  key: 'matriculasState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
