import {ExibeMensagem} from "../components/shared/Message";

export function dispatchGraphError(errors = [], onlyThrowOnAuthContext = false) {
  if (!onlyThrowOnAuthContext) return;
  const dispatch = (errors) => {
    errors.forEach(error => {
      const {
        extensions,
        message
      } = error;
      const {
        response,
        code
      } = extensions || {};
      const {
        body,
        status,
        statusText
      } = response || {};
      const level = status >= 500 ? '2' : '1';

      if (response) {
        if (body) {
          ExibeMensagem(body, level, true);
        } else if (statusText) {
          ExibeMensagem(statusText, level, true);
        }
      } else {
        switch (code) {
          case 'UNAUTHENTICATED':
              ExibeMensagem("Usuário não autenticado.", '2', true);
              // trigger msal login
              window.msalInstance.loginRedirect();
            break;
            default:
              console.error("Erro não tratado: " + message);

        }
      }
    });
  }
  if ('graphQLErrors' in errors) {
    const {
      graphQLErrors,
    } = errors;
    dispatch(graphQLErrors);
  } else if (errors.length > 0) {
    dispatch(errors);
  }
}