import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";

export const showSelectColaboradorState = atom<boolean>({
  key: 'showSelectColaboradorState',
  default: true,
  effects: [
    persistAtomEffect
  ]
});
