import PanelButton from "../PanelButton/PanelButton";
import {AddTwoTone, DeleteTwoTone, DownloadTwoTone} from "@mui/icons-material";
import CustomTable from "../CustomTable";
import {Button} from "react-bootstrap";
import React, {useState} from "react";
import getTableActions from "./getTableActions";
import Swal from "sweetalert2";
import manutencaoFinanceiraModelKeynames from "../../utils/manutencaoFinanceiraModelKeynames";
import OV from "../../models/OV";
import Ficha from "../../models/Ficha";
import Transacao from "../../models/Transacao";
import SearchInput from "../SearchInput/SearchInput";
import includesSearch from "../../utils/includesSearch";
import {v4 as uuidv4} from "uuid";
import SalesOrder from "../../models/SalesOrder";
import FinancialStatement from "../../models/FinancialStatement";
import Transaction from "../../models/Transaction";
import formatDateToDisplay from "../../utils/formatDateToDisplay";

export default function Content(props) {
  const {
    activeTab,
    tableColumns,
    rows,
    isLoadingFetchData,
    setRows,
    data,
    matricula,
    deletingRows,
    setDeletingRows,
    editingRows,
    setEditingRows,
    selectedRows,
    setSelectedRows,
  } = props;
  const [isDeleting, setIsDeleting] = useState(false);
  const [editedRows, setEditedRows] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [search, setSearch] = useState("");

  const tableConfigs = {
    selection: true,
    editing: editingRows,
    actions: getTableActions({editingRows}),
    onClickAction: (action, row) => {
      const events = {
        "edit": () => addEditing(row),
        "copy": () => handleCopyRow(row)(new Event("click")),
      }
      events[action.id]() || console.warn("Unhandled option: ", action);
    },
  }

  const handleCopyRow = row => event => {
    const index = rows.indexOf(row);
    const newRow = {...row};

    newRow.id = `${newRow.id}-copy`;
    newRow.metadata = {
      ...newRow.metadata,
      unsaved: true,
    };

    insertRowAtPosition(index + 1, newRow)
  }

  const insertRowAtPosition = (position, row) => {
    const newRows = [...rows];

    newRows.splice(position, 0, {...row});

    setRows(newRows);
  };

  const filterBySearch = (row) => {
    if (!search) return true;

    const rowValues = Object.values(row);
    const searchValues = rowValues.filter(value => includesSearch(value, search));

    return searchValues.length > 0;
  }

  const handleSearch = (event) => {
    setSearch(event.target.value);
  }

  const handleChangeRow = (rowId, field, value) => {
    const row = editedRows.find(row => row.id === rowId) ||
      rows.find(row => row.id === rowId);
    if (row) {
      const newRow = {...row};
      newRow[field] = value;
      setEditedRows([
        ...editedRows.filter((r) => r.id !== rowId),
        newRow,
      ])
    }
  }

  const addEditing = (row) => {
    setEditingRows([...editingRows.filter(rid => rid !== row.id), row.id]);
  }

  const handleDelete = () => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar esta(s) linha(s)?',
      text: "Esta ação não poderá ser desfeita!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, apagar!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: () => {
        setIsDeleting(true);

        setRows([...rows.filter((row) => !deletingRows.includes(row.id))]);

        return setIsDeleting(false);
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Apagado!',
          'As linhas selecionadas foram apagadas.',
          'success'
        )
        setSelectedRows(selectedRows.filter((row) => !deletingRows.includes(row)));
        setDeletingRows([]);
      }
    })
  }

  const replaceForEditedRows = (row) => {
    const editedRow = editedRows.find((r) => r.id === row.id);
    if (editedRow) {
      return editedRow;
    }
    return row;
  }

  const handleDownload = async () => {
    setIsDownloading(true);
    const downloadRows = [...rows];

    const date = formatDateToDisplay(new Date().toDateString());

    switch (activeTab) {
      case manutencaoFinanceiraModelKeynames.OV:
        const ov = new OV();
        ov.SalesOrderList = downloadRows.map(replaceForEditedRows);
        ov.SalesOrderList = ov.SalesOrderList.map((row) => {
          delete row.id;
          return row;
        });
        ov.MatriculaId = ov.SalesOrderList[0].MatriculaId;
        ov.DataAreaId = ov.SalesOrderList[0].MatriculaId.split('-')[0] ?? ''
        ov.download(`ov-${matricula.codigoMatriculaErp}-${date}`);
        break;
      case manutencaoFinanceiraModelKeynames.FICHA:
        const ficha = new Ficha();
        ficha.FinancialStatementList = downloadRows.map(replaceForEditedRows);
        ficha.FinancialStatementList = ficha.FinancialStatementList.map((row) => {
          delete row.id;
          return row;
        });

        if (data) {
          ficha.DataAreaId = data.DataAreaId ?? '';
          ficha.FiscalEstablishmentId = data.FiscalEstablishmentId ?? '';
          ficha.MatriculaId = data.MatriculaId ?? '';
        }

        ficha.download(`ficha-${matricula.codigoMatriculaErp}-${date}`);
        break;
      case manutencaoFinanceiraModelKeynames.TRANSACAO:
        const transacaoAberta = new Transacao();
        const transacaoFechada = new Transacao();

        /** @type {Transaction[]} */
        const transactions = downloadRows.map(replaceForEditedRows);

        const transOpen = transactions.filter((row) => row.transStatus === 1);
        const transClosed = transactions.filter((row) => row.transStatus === 2);

        if (transOpen.length > 0) {
          transacaoAberta.addTransactions(transOpen);
          transacaoAberta.download(`transacao-aberta-${matricula.codigoMatriculaErp}-${date}`);
        }

        if (transClosed.length > 0) {
          transacaoFechada.addTransactions(transClosed);
          transacaoFechada.download(`transacao-fechada-${matricula.codigoMatriculaErp}-${date}`);
        }
        break;
      default:
        console.warn("Unhandled tab: ", activeTab);
        break;
    }

    setIsDownloading(false);
  }

  const addNewRow = () => {
    const models = {
      ov: SalesOrder,
      ficha: FinancialStatement,
      transacao: Transaction,
    }

    if (rows.length === 0) {
      const newRow = {
        id: uuidv4(),
        ...new models[activeTab](),
      };
      switch (activeTab) {
        case manutencaoFinanceiraModelKeynames.OV:
          newRow.MatriculaID = matricula?.codigoMatriculaErp;
          break;
        case manutencaoFinanceiraModelKeynames.FICHA:
          newRow.FinancialStatementNumber = "0000000000";
          break;
        case manutencaoFinanceiraModelKeynames.TRANSACAO:
          newRow.TransactionNumber = "0000000000";
          break;
        default:
          console.warn("Unhandled tab: ", activeTab);
          break;
      }
      return setRows([newRow])
    }

    return setRows([
      ...rows,
      {
        ...rows[rows.length - 1],
        id: uuidv4(),
      }
    ])
  }

  const handleSelection = (selection = []) => {
    setSelectedRows(selection);
    setDeletingRows(selection);
  }

  return (
    <>
      <div className="filtros">
        <div className="row mb-3">
          <div className="col-md-3">
            <SearchInput
              value={search}
              onChange={handleSearch}
            />
          </div>

          <div className="col-md-9">
            <div className="row justify-content-end m-r-1">
              <PanelButton
                isLoading={isDownloading}
                loadingTitle="Exportando"
                disabled={!rows.length}
                onClick={handleDownload}
                icon={<DownloadTwoTone/>}
                tip="Baixar arquivo JSON com o(s) dado(s) selecionado(s)"
                showBadge={!!rows.length}
                badgeValue={rows.length}
              />

              <PanelButton
                variant="danger"
                isLoading={isDeleting}
                loadingTitle="Removendo linhas"
                disabled={deletingRows.length === 0}
                onClick={handleDelete}
                icon={<DeleteTwoTone/>}
                tip={`Remover ${deletingRows.length} linha(s) selecionada(s)`}
                showBadge={!!deletingRows.length}
                badgeValue={deletingRows.length}
              />
            </div>
          </div>
        </div>
      </div>

      <CustomTable
        columns={tableColumns}
        data={(rows || []).map(replaceForEditedRows).filter(filterBySearch)}
        configs={tableConfigs}
        onEditRow={addEditing}
        selected={selectedRows}
        onChangeRow={handleChangeRow}
        onSelectionChange={handleSelection}
        isLoading={isLoadingFetchData}
      />

      <div className="row justify-content-end m-r-1">
        <Button
          type="button"
          variant="primary"
          style={{marginTop: 15}}
          onClick={addNewRow}
        >
          <AddTwoTone/>
          Nova linha
        </Button>
      </div>
    </>
  )
}
