import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import { ContatoColaborador } from "seb-graph-api-types/generated";
// import {Contato} from "seb-graph-api-types/generated";

export const colaboradoresState = atom<ContatoColaborador[]>({
  key: 'colaboradoresState',
  default: [],
  effects: [
    persistAtomEffect
  ]
});
