import React from "react";
import {Box, Skeleton, Stack, Tooltip} from "@mui/material";
import SvgIcon from "@mui/material/SvgIcon";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import {alpha} from "@mui/system/colorManipulator";
import {PersonTwoTone} from "@mui/icons-material";
import {ResponsiveStyleValue, SystemStyleObject} from "@mui/system";
import {Theme} from "@mui/material/styles/createTheme";
import ZoomInIcon from '@mui/icons-material/ZoomIn';
//@ts-ignore
import {Maybe} from "seb-graph-api-types/generated";

interface Props {
  url?: Maybe<string>
  color?: SystemStyleObject<Theme> | ResponsiveStyleValue<string[] | string | undefined>
  onClick?: () => void
  width?: string | number
  height?: string | number
  disableBorder?: boolean
  disableHover?: boolean
  disableIcon?: boolean
  loading?: boolean
  placeholder?: string
  icon?: any
  noImageIcon?: any
  badgeIcon?: any
  badgeTitle?: string
}

function ProfilePicture(props: Props) {
  const {
    url,
    color = 'neutral.300',
    icon = <ZoomInIcon/>,
    noImageIcon = <PersonTwoTone/>,
    onClick,
    placeholder = "Expandir",
    width = 100,
    height = 150,
    disableBorder = false,
    disableHover = false,
    disableIcon = false,
    loading,
    badgeIcon,
    badgeTitle = ""
  } = props;
  
  return (
    <Stack
      spacing={3}
      width="auto"
      justifyContent="center"
    >
      <Stack
        id="teste"
        justifyContent="center"
        direction="row"
      >
        <Box
          sx={!disableBorder ? {
            borderColor: color,
            borderRadius: '10px',
            borderStyle: 'solid',
            borderWidth: 1,
            p: '4px'
          } : undefined}
        >
          <Box
            sx={{
              borderRadius: '10px',
              height: '100%',
              width: '100%',
              position: 'relative'
            }}
          >
            {badgeIcon && (
              <Box
                sx={{
                  top: 0,
                  left: 0,
                  position: 'absolute',
                  zIndex: 2,
                  padding: 1,
                  borderRadius: '10px',
                }}
              >
                <Tooltip
                  title={badgeTitle}
                >
                  {badgeIcon}
                </Tooltip>
              </Box>
            )}
            <Box
              role="button"
              sx={{
                alignItems: 'center',
                backgroundColor: (theme) => alpha(theme.palette.neutral[700], 0.5),
                borderRadius: '10px',
                color: 'common.white',
                cursor: disableHover ? 'default' : 'pointer',
                display: 'flex',
                height: '100%',
                justifyContent: 'center',
                left: 0,
                opacity: 0,
                position: 'absolute',
                top: 0,
                width: '100%',
                zIndex: 1,
                '&:hover': {
                  opacity: disableHover ? 0 : 1
                }
              }}
              onClick={onClick}
            >
              <Stack
                alignItems="center"
                direction="row"
                spacing={1}
              >
                {icon && (
                  <SvgIcon color="inherit">
                    {icon}
                  </SvgIcon>
                )}
                <Typography
                  color="inherit"
                  variant="subtitle2"
                  sx={{fontWeight: 700}}
                >
                  {placeholder}
                </Typography>
              </Stack>
            </Box>
            {loading ? (
              <Skeleton
                animation="wave"
                width={width}
                height={height}
                sx={{
                  transform: 'none',
                }}
              />
            ) : (
              <Avatar
                src={url ?? ""}
                sx={{
                  height,
                  width,
                  borderRadius: '10px',
                }}
              >
                {!disableIcon && (
                  <SvgIcon>
                    {noImageIcon}
                  </SvgIcon>
                )}
              </Avatar>
            )}
          </Box>
        </Box>
      </Stack>
    </Stack>
  )
}


export default ProfilePicture;
