import React, {useEffect} from 'react';
import {Autocomplete, Chip, Divider, TextField} from "@mui/material";
import * as _contatosService from "../../service/contatos-service";
import {useRecoilState, useRecoilValue, useSetRecoilState} from "recoil";
import {selectedUnidadeState} from "../../recoil/atoms/selectedUnidadeState";
import { colaboradoresState } from 'src/recoil/atoms/colaboradoresState';
import {darken, lighten, styled} from '@mui/system';
import CircularProgress from "@mui/material/CircularProgress";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { selectedColaboradorState } from 'src/recoil/atoms/selectedColaboradorState';
import { ContatoColaborador } from "seb-graph-api-types/generated";
import { loadingColaboradoresState } from 'src/recoil/atoms/loadingColaboradoresState';

const GroupHeader = styled('div')(({theme}) => ({
  position: 'sticky',
  top: '-8px',
  padding: '4px 10px',
  color: theme.palette.primary.main,
  backgroundColor:
    theme.palette.mode === 'light'
      ? lighten(theme.palette.primary.light, 0.85)
      : darken(theme.palette.primary.main, 0.8),
}));

const GroupItems = styled('ul')({
  padding: 0,
});

function ColaboradorAutocompleteSelect(
  {
    maxWidth = 600,
  }
) {
  const setColaborador = useSetRecoilState(selectedColaboradorState);
  const selectedUnidade = useRecoilValue(selectedUnidadeState);
  const [loading, setLoading] = useRecoilState(loadingColaboradoresState);
  const [colaboradores, setColaboradores] = useRecoilState<ContatoColaborador[]>(colaboradoresState);
  const colaborador = useRecoilValue<ContatoColaborador>(selectedColaboradorState);
  
  const options = (colaboradores || []).map(u => ({
    label: `${u?.nome} - Email: ${u?.email}`,
    value: u
  }));

  const selected = colaborador !== null ? {
    label: `${colaborador?.nome} - Email: ${colaborador?.email}`,
    value: colaborador
  } : null;

  useEffect(() => {
    if (selectedUnidade && selectedUnidade.id) {
      setLoading(true);
      setColaboradores([]);
      _contatosService.GetColaboratorsByUnit(selectedUnidade.id)
        .then(colaboradores => {
          setColaboradores(colaboradores);
          if(colaborador)
            setColaborador(colaboradores.find(c => c.id === colaborador.id));
          setLoading(false)
      })
    }
  }, [selectedUnidade]);
  
  useEffect(() => {
    const url = new URL(window.location.href)
    const colaboradorId = url.searchParams.get('colaboradorId');
    
    if (colaboradorId && colaboradores && colaboradores.length && !colaborador) {
      setColaborador((colaboradores || []).find(u => u.id === colaboradorId))
    }
  }, [colaboradores, colaborador]);


  return (
    <React.Fragment>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          width: '100%',
          maxWidth,
          alignItems: 'center',
          m: 2,
          gap: 2,
        }}
      >
        <Autocomplete
          openOnFocus
          disablePortal
          options={options.sort((a, b) => -b.value.nome.localeCompare(a.value.nome))}
          isOptionEqualToValue={(option, value) => option.value.id === value.value.id}
          groupBy={(option) => option.label.charAt(0).toUpperCase()}
          sx={{
            maxWidth: 600,
            width: '100%',
          }}
          // @ts-ignore
          value={selected}
          // @ts-ignore
          onChange={(event, newValue: { label: string, value: Matricula }) => {
            setColaborador(newValue.value);
          }}
          size={'small'}
          disabled={!selectedUnidade || !selectedUnidade.id}
          noOptionsText={'Nenhum colaborador encontrado'}
          loading={loading}
          disableClearable
          loadingText={'Carregando...'}
          renderOption={(props, option) => (
            <Box component="li" sx={{width: "100%"}} {...props}>
              <Stack spacing={1} sx={{width: "100%"}}>
                <Typography
                  color="primary"
                  variant="h6"
                >
                  <b>{option?.value?.nome}</b>
                </Typography>
                 <Stack spacing={1} direction="column">
                  <Typography variant="caption">
                    <b>Email:</b> {option?.value?.email}
                  </Typography>
                </Stack>
                <Stack spacing={1} direction="column">
                  <Typography variant="caption">
                    <b>Status:</b> {" "}
                    <Chip
                      variant="outlined"
                      size="small"
                      label={option?.value?.urlImagemPerfil ? "Com foto" : "Sem foto"}
                      color={option?.value?.urlImagemPerfil ? "primary" : "error"}
                    />
                  </Typography>
                </Stack>
                <Divider/>
              </Stack>
            </Box>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Colaborador"
              autoComplete="off"
              type="text"
              name="unidade"
              InputProps={{
                ...params.InputProps,
                autoComplete: 'off',
                startAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="primary" size={20}/> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
          renderGroup={(params) => (
            <li key={params.key}>
              <GroupHeader>{params.group}</GroupHeader>
              <GroupItems>{params.children}</GroupItems>
            </li>
          )}
        />
      </Box>
    </React.Fragment>
  );
}

export default ColaboradorAutocompleteSelect;
