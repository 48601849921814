const contextKeys = {
  tables: {
    FRANK_OV_SALE_ORDER: "frank-ov-sale-order",
    FRANK_FICHA_FINANCIAL_STATEMENT: "frank-ficha-financial-statement",
    FRANK_TRANSACAO_TRANSACTION: "frank-transacao-transaction",
    REPLAY_PARCIAL_REGULAR: "replay-parcial-regular",
    FINANCEIRO_TABLE_DETAILS: "financeiro-table-details",
    RESPONSAVEIS_TABLE: "responsaveis-table",
  }
}

Object.freeze(contextKeys);

export default contextKeys;
