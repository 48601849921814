const blockedResourcesSystem = {
  SMO: '8bbb73d1-06d8-402a-a674-43a262971237',
  Secretaria: 'a3b850c1-8912-4bcd-9b99-5fc8fde4d1c1',
  Portal: 'bbc7af15-377f-45cc-b398-b83f5f22c2e8',
}

const blockedResourcesSystemNames = {
  [blockedResourcesSystem.SMO]: 'Matrícula Digital',
  [blockedResourcesSystem.Secretaria]: 'Secretaria Web',
  [blockedResourcesSystem.Portal]: 'Portal Financeiro',
}

export { blockedResourcesSystemNames };

export default blockedResourcesSystem;