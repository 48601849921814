import React, { Fragment, useCallback, useEffect, useState } from 'react'
import * as Message from '../../components/shared/Message'
import * as _matriculaService from '../../service/matricula-service.js'
import * as _cancelamentoService from '../../service/cancelamento-service'
import * as _movimentacoesService from '../../service/movimentacoes-service.js'
import * as _utils from '../../Utils.js'
import PersonIcon from '@mui/icons-material/Person';
import { Matricula } from "../../components/MatriculaAutocompleteSelect/MatriculaAutocompleteSelect";
import { Unidade } from "../../components/UnidadeAutocompleteSelect/UnidadeAutocompleteSelect";
import withUnidadeMatricula from '../../hocs/withUnidadeMatricula'
import csatList from '../../utils/csatList'
import InfoComponent from '../../components/InfoComponent'
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import { Box, Container, Grid, Grow, MenuItem, Select, Stack, TextField, Typography, Button, InputLabel, CircularProgress } from '@mui/material'
import ErrorMessage from 'src/components/ErrorMessage/ErrorMessage'
import type { FileRejection, FileWithPath } from 'react-dropzone';
import { FileDropzone } from 'src/components/file-dropzone/file-dropzone'

export type File = FileWithPath;
interface TypedProperty {
  valor: number,
  label: string
}
interface StatusCancelamento {
  criadoEm: string,
  descricaoCancelamento: string,
  isencaoMultaAprovada: boolean,
  mensagemDeProcessamento: string,
  motivoCancelamentoId: string,
  motivoDeRecusa: string,
  razaoStatus: TypedProperty,
  valorDaMulta: number
}

interface Props {
  Matricula: Matricula,
  Unidade: Unidade
  handleShowCsat: (key: string) => void
}

interface MotivoCancelamento {
  codigo: string,
  id: string,
  descricao: string
}

const statusInitial: StatusCancelamento = {
  criadoEm: '',
  descricaoCancelamento: '',
  isencaoMultaAprovada: false,
  mensagemDeProcessamento: '',
  motivoDeRecusa: '',
  motivoCancelamentoId: '',
  razaoStatus: {
    valor: null,
    label: ''
  },
  valorDaMulta: 0
}

const Cancelamento = (props: Props) => {
  const {
    Matricula,
    Unidade,
    handleShowCsat
  } = props;

  const [isLoadingConfirmacao, setIsLoadingConfirmacao] = useState<boolean>(false);
  const [isLoadingCarregamento, setIsLoadingCarregamento] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matriculaCancelada, setMatriculaCancelada] = useState<boolean>(false);
  const [dataCancelamento, setDataCancelamento] = useState<string>('Não Cancelada');
  const [cancelamentoMatricula, setCancelamentoMatricula] = useState<string>(null);
  const [cancelamentoDescricaoMatricula, setCancelamentoDescricaoMatricula] = useState<string>('');
  const [statusCancelamento, setStatusCancelamento] = useState<StatusCancelamento>(statusInitial);
  const [motivosCancelamento, setMotivosCancelamento] = useState([]);
  const [motivoSelecionado, setMotivoSelecionado] = useState<MotivoCancelamento>();
  const [descricaoCancelamento, setDescricaoCancelamento] = useState<string>('');
  const [anexo, setAnexo] = useState<string>('');
  const [extensao, setExtensao] = useState<string>('');
  const [precisouDeAprovacao, setPrecisouDeAprovacao] = useState<boolean>(false);
  const [isencaoMultaSelecionado, setIsencaoMultaSelecionado] = useState<string>('Solicitar isenção de multa?');
  const [isencaoDaMultaSolicitada, setIsencaoDaMultaSelecionada] = useState<boolean>(false);
  const [servicoPermiteIsencaoDaMulta, setServicoPermiteIsencaoDaMulta] = useState<boolean>(false);
  const [valorMulta, setValorMulta] = useState<number>(0);
  const [errorString, setErrorString] = useState<string>();
  const [permiteMovimentarAvista, setPermiteMovimentarAvista] = useState<boolean>(true);
  const [files, setFiles] = useState<File[]>([]);
  const maxSize = 10000000;

  const handleFilesDrop = useCallback(
    (newFiles: File[], rejectedFiles: FileRejection[]): void => {
      if (rejectedFiles.length > 0) {
        rejectedFiles.forEach((file) => {
          file.errors.forEach((error) => {
            if (error.code === 'file-too-large') {
              Message.ExibeMensagem("Arquivo muito Grande! O tamanho máximo para o arquivo é de " + maxSize / 1000000 + "MB", 1);
            }
            if (error.code === 'file-invalid-type') {
              Message.ExibeMensagem("Tipo de arquivo inválido!", 1);
            }
          })
        })
        return
      }
      if (newFiles.length > 1) {
        Message.ExibeMensagem("Apenas um arquivo pode ser enviado.", 1);
        return;
      }
      let hasError = false;
      if (newFiles.length > 0) {
        newFiles.forEach((file) => {
          if (file.size > maxSize) {
            Message.ExibeMensagem("Arquvio muito Grande! O tamanho máximo para o arquivo é de " + maxSize / 1000000 + "MB", 1);
            hasError = true;
          }
        })
        if (hasError) return
      }
      const file = newFiles[0];
      const reader = new FileReader();
      setFiles(newFiles);

      reader.onload = () => {
        const fileContent = reader.result as string;
        setAnexo(fileContent.split(',')[1]);
        setExtensao(file.name.split('.').pop());
      };

      reader.readAsDataURL(file);
    },
    []
  );
  const handleFileRemove = useCallback(
    (file: File): void => {
      setFiles((prevFiles) => {
        return prevFiles.filter((_file) => _file.path !== file.path);
      });
      setAnexo('');
      setExtensao('');
    },
    []
  );

  const criarCancelamento = () => {
    try {
      if (!motivoSelecionado?.id) {
        Message.ExibeMensagem('Deve ser selecionado um motivo.', 1, true)
        return
      }

      let solicitaIsencaoMulta =
        isencaoMultaSelecionado === 'Sim' ? true : false

      var request = {
        idMatricula: Matricula?.idMatricula,
        idMotivoCancelamento: motivoSelecionado?.id,
        descricaoCancelamento: descricaoCancelamento,
        isencaoDaMultaSolicitada: solicitaIsencaoMulta,
        anexo: anexo,
        extensao: extensao
      }

      setIsLoadingConfirmacao(true)
      _cancelamentoService.PostCriarCancelamento(request).then((result) => {
        if (result === undefined) {
          Message.ExibeMensagem("Erro ao processar cancelamento.", 1, true);
        } else {
          if (result.statusCode !== 0) {
            Message.ExibeListMensagem(result.messages, true, "");
          } else {
            if (result.value?.length > 0) {
              result.value.map((value) => {
                if (_utils.ValidaBoleto(value)) {
                  _utils.DownloadArquivoPDF(value.boleto, "boleto_cancelamento");
                }
                return value;
              });
            }

            if (
              result.messages !== null &&
              result.messages !== undefined &&
              result.messages.length > 0 &&
              result.messages[0].text === "Solicitado"
            ) {
              Message.ExibeMensagem("Uma solicitação de aprovação de isenção foi criada.Aguardando aprovação.", '0', true);
              setIsLoading(true)
              CarregarMultaCancelamento()
              CarregarMotivosCancelamento()
              VerificaMatriculaCancelada()
              VerificarPermiteMovimentarAvista()
            } else {
              Message.ExibeListMensagem(result.messages, true, "");
              setDataCancelamento(formatData(new Date().toISOString()))
              setMatriculaCancelada(true)
            }
            setIsLoadingConfirmacao(false);
          }
        }
      }).catch(() => {
        setIsLoadingConfirmacao(false)
      }).finally(() => {
        if (handleShowCsat) handleShowCsat(csatList.cancelamento)
      });
    } catch (e) {
      console.error(e)
    }
  }

  const formatData = (data: string) => {
    return data?.split('T')[0] ?? 'Não Cancelada'
  }
  const CarregarMotivosCancelamento = async () => {
    setIsLoadingCarregamento(true)
    var motivosCancelemento = await _matriculaService.ListMotivosCancelamento()
    if (motivosCancelemento != null) {
      setMotivosCancelamento(motivosCancelemento)
    }
    setIsLoadingCarregamento(false)
  }



  useEffect(() => {
    if (Matricula) {
      setIsLoading(true)
      setMatriculaCancelada(false)
      setPrecisouDeAprovacao(false)
      CarregarMultaCancelamento()
      setCancelamentoDescricaoMatricula('')
      setCancelamentoMatricula('')
      setErrorString(null)
      VerificaMatriculaCancelada()
      setMotivosCancelamento([])
      VerificarPermiteMovimentarAvista()
      setValorMulta(0)
      setStatusCancelamento(statusInitial)
      setDescricaoCancelamento('')
      setMotivoSelecionado(null)
      setServicoPermiteIsencaoDaMulta(false)
      setIsencaoDaMultaSelecionada(false)
    }
  }, [Matricula]);

  const CarregarMultaCancelamento = async () => {
    await _cancelamentoService
      .GetMultaCancelamento(Matricula.idMatricula)
      .then(result => {
        if (result === null || result === undefined) {
          Message.ExibeMensagem('Cálculo para multa não cadastrado.', 1, true)
        } else {
          let valorMulta = 0
          if (result) {
            if (result.valorMultaList) {
              result.valorMultaList.forEach(obj => {
                valorMulta += obj.valor
              })
            }

            if (result.cancelamentoMatricula) {
              setPrecisouDeAprovacao(true)
              let statusCancelamento = result.cancelamentoMatricula
              if (statusCancelamento.criadoEm)
                statusCancelamento.criadoEm = formatData(statusCancelamento.criadoEm)
              setStatusCancelamento(statusCancelamento)
            }
          }

          setValorMulta(valorMulta)
          setServicoPermiteIsencaoDaMulta(result.servicoPermiteIsencaoDaMulta)
          setIsencaoDaMultaSelecionada(result.isencaoDaMultaSolicitada)
        }
      })
  }

  const VerificaMatriculaCancelada = async () => {
    try {
    await _matriculaService
      .GetMatricula(Matricula?.idMatricula)
      .then(result => {
        CarregarMotivosCancelamento()
        if (result.razaoStatus_Value === 956840003) {
          console.log(result.motivoDoCancelamento)
          setCancelamentoMatricula(result.motivoDoCancelamento)
          setCancelamentoDescricaoMatricula(result.descricaoDoCancelamento)
          setMatriculaCancelada(true)
          setDataCancelamento(formatData(result.dataDeCancelamento))
        }
      })
    }catch(e){
      setErrorString('Erro ao buscar a Matrícula do Aluno.');
    }
  }
  const VerificarPermiteMovimentarAvista = async () => {
    var idUnidade = Unidade.id
    const resp = await _movimentacoesService.PermiteMovimentarAvista(
      Matricula.idMatricula,
      idUnidade,
      284360007,
      0
    )
    setPermiteMovimentarAvista(resp);
    setIsLoading(false)
  }

  useEffect(() => {
    setIsLoading(true)
    CarregarMultaCancelamento()
    CarregarMotivosCancelamento()
    VerificaMatriculaCancelada()
    VerificarPermiteMovimentarAvista()
  }, [])

  if (isLoading) {
    return <LoadingAnimation />
  } else if (errorString != null) {
    const error = "Ocorreu o(s) seguinte(s) Erro(s): " + errorString
    return <ErrorMessage message={error} />
  }
  if (!permiteMovimentarAvista) {
    return (
      <div>
        <div className=' form-group d-flex justify-content-center'>
          <h3 style={{ color: '#0068b2' }}>
            <strong>AVISO</strong>
          </h3>
        </div>
        <div className=' form-group d-flex justify-content-center'>
          <h5 style={{ color: '#0068b2' }}>
            {' '}
            O Template selecionado não permite movimentações onde o pagamento
            é Avista.
          </h5>
        </div>
      </div>
    )
  }
  if (matriculaCancelada || isencaoDaMultaSolicitada) {
    return (
      <React.Fragment>
        <Box
          component="main"
          sx={{
            flexGrow: 1,
          }}
        >
          <Grid
            container
            justifyContent="center"
          >
            <Grid
              md={12}
              width="100%"
              sx={{
                mb: 3
              }}
            >
              <InfoComponent
                titleOption={'Matrícula Cancelada'}
                title='Solicitação de Cancelamento em análise'
                whichTitle={isencaoDaMultaSolicitada && !matriculaCancelada}
                descOption={'A matricula ' + Matricula?.codigoMatricula + ' foi cancelada com sucesso.'}
                desc={`A solicitação de cancelamento da matrícula ${Matricula?.codigoMatricula} foi realizada com sucesso e aguarda aprovação.`}
                whichDescription={isencaoDaMultaSolicitada && !matriculaCancelada}
                type={isencaoDaMultaSolicitada && !matriculaCancelada ? 'info' : 'success'}
              />
            </Grid>
            <Grid
              md={12}
              width="100%"
            >
              <Container maxWidth='lg'>
                <Grid
                  alignItems='center'
                  container
                  sx={{
                    backgroundColor: 'var(--bs-gray-200)',
                    borderRadius: 1,
                    px: 4,
                    py: 8
                  }}
                >
                  <Grow
                    in={!isLoadingCarregamento}
                    easing="ease-out"
                    mountOnEnter
                    unmountOnExit
                    timeout={800}
                  >
                    <Grid
                      md={12}
                    >
                      <Typography
                        variant="h5"
                        sx={{
                          mb: 3
                        }}
                      >
                        Informações do Cancelamento
                      </Typography>
                      {precisouDeAprovacao &&
                        <Stack
                          direction={{ md: 'row', sm: 'column' }}
                          spacing={3}
                          sx={{
                            mb: 3
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Data da Solicitação"
                            type="date"
                            name="criadoEm"
                            size="small"
                            value={statusCancelamento.criadoEm}
                            variant="filled"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                          <TextField
                            fullWidth
                            label="Isenção da Multa foi Aprovada?"
                            name="isencaoAprovada"
                            size="small"
                            value={statusCancelamento.isencaoMultaAprovada ? 'Sim' : 'Não'}
                            variant="filled"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Stack>
                      }


                      <Stack
                        direction={{ md: 'row', sm: 'column' }}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Data do Cancelamento"
                          type="date"
                          name="dataCancelamento"
                          size="small"
                          value={dataCancelamento}
                          variant="filled"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        <TextField
                          fullWidth
                          label="Valor da Multa"
                          name=""
                          size="small"
                          InputLabelProps={{ shrink: true }}
                          value={'R$ ' + _utils.FormatarMoedaToLocale(valorMulta)}
                          variant="filled"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                      </Stack>

                      <Stack
                        direction={{ md: 'row', sm: 'column' }}
                        spacing={3}
                        sx={{
                          mb: 3
                        }}
                      >
                        <TextField
                          fullWidth
                          label="Cancelamento Aprovado?"
                          name="cancelamentoAprovado"
                          size="small"
                          value={matriculaCancelada ? 'Sim' : 'Não'}
                          variant="filled"
                          InputProps={{
                            readOnly: true,
                          }}
                        />
                        {precisouDeAprovacao &&
                          <TextField
                            fullWidth
                            label="Razão do Status"
                            name="razaoStatus"
                            size="small"
                            value={statusCancelamento.razaoStatus.label}
                            variant="filled"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        }
                      </Stack>

                      <>
                        <Stack
                          direction={{ md: 'row', sm: 'column' }}
                          spacing={3}
                          sx={{
                            mb: 3
                          }}
                        >
                          <TextField
                            fullWidth
                            label="Descrição do Cancelamento"
                            name="descricaoCancelamento"
                            size="small"
                            value={statusCancelamento.descricaoCancelamento && statusCancelamento.descricaoCancelamento !== ''  ? statusCancelamento.descricaoCancelamento : cancelamentoDescricaoMatricula}
                            variant="filled"
                            InputProps={{
                              readOnly: true,
                            }}
                            multiline
                            maxRows={3}
                          />
                          <TextField
                            fullWidth
                            label="Motivo do Cancelamento"
                            name="motivoCancelamento"
                            size="small"
                            value={motivosCancelamento.find(motivo => motivo.id === statusCancelamento.motivoCancelamentoId)?.descricao ?? cancelamentoMatricula}
                            variant="filled"
                            InputProps={{
                              readOnly: true,
                            }}
                          />
                        </Stack>
                        {precisouDeAprovacao &&
                          <Stack
                            direction={{ md: 'row', sm: 'column' }}
                            spacing={3}
                            sx={{
                              mb: 3
                            }}
                          >
                            <TextField
                              fullWidth
                              label="Mensagem de Processamento"
                              name="mensagemProcessamento"
                              size="small"
                              variant="filled"
                              value={statusCancelamento.mensagemDeProcessamento}
                              InputProps={{
                                readOnly: true,
                              }}
                              multiline
                              maxRows={3}
                            />
                          </Stack>
                        }
                      </>
                    </Grid>
                  </Grow>
                </Grid>
              </Container>
            </Grid>
          </Grid>
        </Box >
      </React.Fragment >
    )
  }

  return (
    <React.Fragment>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
        }}
      >
        <Container maxWidth="xl">
          <Grid
            container
            justifyContent="center"
            alignItems='center'
            spacing={3}
            sx={{
              backgroundColor: 'var(--bs-gray-200)',
              borderRadius: 1,
              px: 2,
              py: 4,
              mb: 3
            }}
          >
            <Grid
              item
              xs={12}
              width="100%"
            >
              <Stack
                spacing={1}
                direction={'row'}
                justifyContent={'flex-start'}
                alignItems={'center'}
                sx={{
                  mb: 4
                }}
              >
                <Typography variant="h4">
                  Informações da Matrícula
                </Typography>
                <PersonIcon
                  sx={{
                    fontSize: '2rem',
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              width="100%"
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
              >
                <TextField
                  fullWidth
                  label="Nome"
                  name="estudante_Name"
                  size="small"
                  value={Matricula?.estudante_Name}
                  variant="filled"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              width="100%"
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
              >
                <TextField
                  fullWidth
                  label="Matrícula"
                  name="codigoMatricula"
                  size="small"
                  value={Matricula?.codigoMatricula}
                  variant="filled"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={6}
              md={3}
              width="100%"
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
              >
                <TextField
                  fullWidth
                  label="Valor da Multa"
                  name=""
                  size="small"
                  InputLabelProps={{ shrink: true }}
                  value={'R$ ' + _utils.FormatarMoedaToLocale(valorMulta)}
                  variant="filled"
                  InputProps={{
                    readOnly: true,
                  }}
                />
              </Stack>
            </Grid>
            <Grid
              item
              md={12}
              width="100%"
            >
              <Typography
                variant="h6"
                color="error"
              >
                Atenção: Ao confirmar o cancelamento da matrícula, não será possível voltar o processo.
              </Typography>
              {!servicoPermiteIsencaoDaMulta &&
                <Typography
                  variant="h6"
                  color="error"
                >
                  Atenção: O serviço não permite isenção de multa.
                </Typography>
              }
            </Grid>
          </Grid>
          <Grid
            container
            spacing={3}
            justifyContent="flex-end"
            alignItems='center'
          >
            <Grid
              item
              xs={12}
              md={6}
              width="100%"
            >
              <Stack>
                <InputLabel
                  id="motivoCancelamento"
                >
                  Motivo do Cancelamento
                </InputLabel>
                <Select
                  fullWidth
                  label="Motivo do Cancelamento"
                  name="motivoCancelamento"
                  size="small"
                  value={motivosCancelamento?.find(motivo => motivo.id === motivoSelecionado?.id) || ''}
                  variant="filled"
                  onChange={e => {
                    console.log(e.target.value);
                    setMotivoSelecionado(e.target.value);
                  }}
                >
                  {motivosCancelamento?.map((motivo, idx) => (
                    <MenuItem
                      key={idx}
                      value={motivo}
                    >
                      {motivo.descricao}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              md={6}
              width="100%"
            >
              <Stack>
                <InputLabel
                  id="isencaoMulta"
                >
                  {!servicoPermiteIsencaoDaMulta ? <Typography color="error">Isenção de Multa</Typography> : 'Isenção de Multa'}
                </InputLabel>
                <Select
                  fullWidth
                  label="Isenção de Multa"
                  name="isencaoMulta"
                  size="small"
                  disabled={!servicoPermiteIsencaoDaMulta}
                  value={isencaoMultaSelecionado}
                  variant="filled"
                  onChange={e => setIsencaoMultaSelecionado(e.target.value)}
                >
                  <MenuItem
                    value={'Sim'}
                  >
                    Sim
                  </MenuItem>
                  <MenuItem
                    value={'Não'}
                  >
                    Não
                  </MenuItem>
                </Select>
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              width="100%"
            >
              <Stack
                direction={{ md: 'row', sm: 'column' }}
              >
                <TextField
                  fullWidth
                  label="Descrição do Cancelamento"
                  name="descricaoCancelamento"
                  size="small"
                  value={descricaoCancelamento}
                  variant="filled"
                  onChange={e => setDescricaoCancelamento(e.target.value)}
                  multiline
                  minRows={3}
                />
              </Stack>
            </Grid>
            <Grid
              item
              xs={12}
              width="100%"
            >
              <FileDropzone
                  accept={{ 'image/*': [], 'application/pdf': [] }}
                  caption="SVG, JPG, PNG, PDF ou gif"
                  files={files}
                  onDrop={handleFilesDrop}
                  onRemove={handleFileRemove}
                  maxSize={maxSize}
                />
            </Grid>
            <Grid
              item
              xs={4}
              width="100%"
            >
              <Button
                name="Confirmar"
                className="btn btn-primary"
                fullWidth
                variant='contained'
                disabled={
                  isencaoDaMultaSolicitada ||
                  isLoadingConfirmacao ||
                  isLoadingCarregamento ||
                  !motivoSelecionado?.id ||
                  (!servicoPermiteIsencaoDaMulta && isencaoMultaSelecionado === 'Solicitar isenção de multa?')
                }
                onClick={() => {
                  criarCancelamento();
                }}
                style={{ width: '100%' }}
                startIcon={
                  <Fragment>
                    {isLoadingConfirmacao ? <CircularProgress color="primary" size={20} /> : null}
                  </Fragment>
                }
              >
                {isLoadingConfirmacao ? 'Cancelando…' : 'Cancelar Matrícula'}
              </Button>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </React.Fragment>
  )
}


export default withUnidadeMatricula(
  Cancelamento,
  'Cancelamento',
  csatList.cancelamento
)
