import React from 'react';
import {Badge, Button, Spinner} from "react-bootstrap";

function PanelButton(
  {
    isLoading,
    disabled,
    onClick,
    loadingTitle,
    icon,
    tip,
    showBadge,
    badgeValue,
    variant = "light"
  }
) {
  return (
    <Button
      type="button"
      variant={variant}
      disabled={disabled}
      style={{marginTop: 15, marginRight: 15}}
      onClick={onClick}
      data-tip={tip}
    >
      {isLoading ? (
        <Spinner
          animation="border"
          role="status"
          size="sm"
        >
          <span className="visually-hidden">{loadingTitle}...</span>
        </Spinner>
      ) : (
        <>
          {icon}
          {!!showBadge && <Badge bg="secondary">{badgeValue}</Badge>}
        </>
      )}
    </Button>
  );
}

export default PanelButton;