export default function objectPropFirstLetterToUpper(obj) {
  if (obj === null || typeof obj !== "object") {
    return obj;
  }

  if (Array.isArray(obj)) {
    return obj.map(objectPropFirstLetterToUpper);
  }

  const newObj = {};

  for (const key in obj) {
    if (Object.hasOwnProperty.call(obj, key)) {
      const value = obj[key];
      const newKey = key.charAt(0).toUpperCase() + key.slice(1);
      newObj[newKey] = objectPropFirstLetterToUpper(value);
    }
  }

  return newObj;
}