import React, { Component } from 'react'
import '../../styles/css/app.css'
import { CurrencyDollar } from 'react-bootstrap-icons'
import * as _financeiroService from '../../service/financeiro-service'
import * as _util from '../../Helpers/utils'
import { AuthContext } from "../../context/AuthContext";
import InfoIcon from '@mui/icons-material/Info';
import CustomTable from '../../components/CustomTable'
import withAppContext from '../../hocs/withAppContext'
import AlertBloqueio from '../../components/AlertBloqueio/AlertBloqueio'
import withUnidadeMatricula from '../../hocs/withUnidadeMatricula'
import LoadingAnimation from '../../components/LoadingAnimation/LoadigAnimation'
import contextKeys from '../../utils/contextKeys'
import "./styles.css"

class Financeiro extends Component {
  constructor(props) {
    super(props)
    this.state = this.getState()
  }

  getState() {
    return {
      data: [],
      Matricula: this.props.Matricula,
      isLoading: false
    }
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.Matricula !== this.props.Matricula) {
      this.setState(this.getState(), () => {
        this.componentDidMount()
      })
    }
  }

  async componentDidMount() {
    this.setState({ isLoading: true })
    await this.getData();
    this.setState({ isLoading: false })
  }

  async getData() {
    var data = await _financeiroService.GetFinanceiro(
      this.state.Matricula.idMatricula
    )
    this.setState({ data: data.parcelas ?? [] })
  }


  CarregarParcela = (parcela, index) => {
    if (!parcela) return null
    const primeiraData = {}
    primeiraData.id = index
    primeiraData.responsavel = parcela.responsavel
    primeiraData.nomeResponsavel = parcela.nomeResponsavel
    primeiraData.numeroFatura = parcela.numeroFatura
    primeiraData.datavencimento = parcela.datavencimento
    primeiraData.vencido = parcela.vencido
    primeiraData.valorOriginal = _util.FormatarMoedaToLocale(
      parcela.valorOriginal
    )
    primeiraData.valorPagar = _util.FormatarMoedaToLocale(parcela.valorPagar)
    primeiraData.valorPago = parcela?.isAcordo ? "acordo" : _util.FormatarMoedaToLocale(parcela.valorPago)
    primeiraData.dataPagamento =
      parcela.dataPagamento == '01/01/1900' ? '' : parcela.dataPagamento
    primeiraData.detalhes = parcela.detalhes
    if (!parcela.detalhes && !parcela.detalhes.length > 0) return null
    primeiraData.detalhes.tipoColumn = "financeiro-rows-notAcordo";
    return primeiraData
  }

  render() {
    const { _appContext } = this.props
    const { blockedResources = [] } = _appContext
    const { data, isLoading } = this.state
    const showDetailRow = (row) => {
      const allElements = document.querySelectorAll(".tableStyle .hideDetails");
      allElements.forEach(element => {
        if (element.classList.contains("row-" + row.id)) {
          if (element.style.display === "flex") {
            element.style.display = "none";
          } else {
            element.style.display = "flex";
          }
        } else {
          element.style.display = "none";
        }
      });
    }
    const blockedFinanceiro = (blockedResources || []).find(
      x =>
        x.action === 'Financeiro/SecretariaFinanceiroGet' &&
        x.matriculaId === this.state.Matricula.idMatricula
    )
    const tableConfigs = {
      selection: false,
      order: {
        defaultColumn: 'nomeResponsavel',
        defaultOrder: 'asc'
      },
      actions: [ { id: "show-detail", label: <><InfoIcon /> Detalhes</>, } ],
      onClickAction: (action, row) => {
        switch (action.id) {
          case "show-detail":
            showDetailRow(row);
            break;
          default:
            console.warn("Unhandled option: ", action);
            break;
        }
      },
    }

    if (blockedFinanceiro) {
      return (
        <AlertBloqueio mensagemBloqueio={blockedFinanceiro?.mensagemBloqueio} />
      )
    }

    if (isLoading) {
      return <LoadingAnimation />
    } else {
      return (
        <AuthContext.Consumer>
          {({ appContext }) => {
            const { tables } = appContext || {};
            const colunasFinanceiro = tables[contextKeys.tables.FINANCEIRO_TABLE_DETAILS]?.columns || [];
            return (
              <div className='row p-b-10 financeiro-table'>
                <legend className='pull-left width-full'>
                  Parcelas <CurrencyDollar className='fa-icon iconeAzul' />
                </legend>
                <div className='p-10'>
                  <CustomTable
                    columns={colunasFinanceiro}
                    data={(data || []).map(this.CarregarParcela)}
                    configs={tableConfigs}
                    detailsData
                  />
                </div>
              </div>
            )
          }}
        </AuthContext.Consumer>
      )
    }
  }
}

export default withAppContext(withUnidadeMatricula(Financeiro, 'Financeiro'))
