import React, {Component} from 'react';
import {Button, Nav, NavDropdown} from "react-bootstrap";
import * as Message from '../../components/shared/Message';
import * as _movimentacoesService from '../../service/movimentacoes-service';
import {PersonFill} from 'react-bootstrap-icons';
import * as formValidacao from "../../Helpers/form-validacao.js";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";
import LoadingAnimation from "../../components/LoadingAnimation/LoadigAnimation";
import csatList from "../../utils/csatList";
import tipoBolsaEnum from "../../utils/tipoBolsaEnum";

class Bolsas extends Component {
  static displayName = Bolsas.name;

  constructor(props) {
    super(props);
    this.state = this.getState()
  }

  getState() {
      return {
          Matricula: this.props.Matricula,
          errors: {},
          listaTipoBolsaNova: [],
          bolsaAlterada: false,
          dadosAtuais: {
              matriculaId: this.props.Matricula.idMatricula,
              tipoDaBolsa: "",
              percentualBolsa: 0,
              hasMovimentacaoPendente: false,
              movimentacaoBolsaId: "",
              movimentacaoMotivoAlteracao: "",
              movimentacaoTipoDaBolsa: "",
              movimentacaoPercentualBolsa: 0
          },
          motivoAlteracao: "",
          tipoBolsaNova: "",
          percentualBolsaNova: 0,
          Selects: {
              motivoAlteracao: "",
              tipoBolsaNova: "",
              percentualBolsaNova: "",
              dataInicio: "",
              dataTermino: "",
              descricao: ""
          },
          permiteMovimentarAvista: true,
          formIsValid: false
      };
  }

  async componentDidMount() {
    this.setState({isLoading: true});
    var listaTipoBolsa = await _movimentacoesService.GetListaTipoBolsa();
    if (listaTipoBolsa != null && listaTipoBolsa != undefined) {
      const hasBosaIrmaoAccess = (this.props.permissions || []).find(p => p.permissionName === "movimentacoes.bolsa.irmao")
      const canUseBolsaIrmao = bolsa => (bolsa.value === tipoBolsaEnum.Irmao && hasBosaIrmaoAccess) || bolsa.value !== tipoBolsaEnum.Irmao
      this.setState({
        listaTipoBolsaNova: (listaTipoBolsa || []).filter(canUseBolsaIrmao)
      });
    }

    var idUnidade = this.props.Unidade.id;
    var permiteMovimentarAvista = await _movimentacoesService.PermiteMovimentarAvista(this.state.Matricula.idMatricula, idUnidade, 284360006, 0);
    this.setState({permiteMovimentarAvista: permiteMovimentarAvista.value});

    await this.getBolsaAtual();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
      if (prevProps.Matricula !== this.props.Matricula) {
          this.setState(this.getState(), () => {
              this.componentDidMount()
          })
      }
  }

    async getBolsaAtual() {

    this.setState({isLoading: true});

    var dadosAtuais = await _movimentacoesService.GetBolsaAtual(this.state.Matricula.idMatricula);

    var campos = this.state.Selects;

    if (dadosAtuais.hasMovimentacaoPendente) {

      let tipoBolsaNova = this.state.listaTipoBolsaNova.find(function (tipo) {
        return tipo.name === dadosAtuais.movimentacaoTipoDaBolsa.replace(" ", "");
      });
      this.SelecioneBolsa(tipoBolsaNova);
      campos.motivoAlteracao = dadosAtuais.movimentacaoMotivoAlteracao;
      campos.tipoBolsaNova = tipoBolsaNova?.value ?? "";
      campos.percentualBolsaNova = dadosAtuais.movimentacaoPercentualBolsa + "%";
      campos.dataInicio = dadosAtuais.dataInicio.substring(0, 10);
      campos.dataTermino = dadosAtuais.dataTermino.substring(0, 10);
      campos.descricao = dadosAtuais?.descricao ?? "";
      campos.nomeSolicitante = dadosAtuais?.nomeSolicitante ?? "";

    } else {
      campos.motivoAlteracao = "";
      campos.tipoBolsaNova = "";
      campos.percentualBolsaNova = "";
      campos.dataInicio = "";
      campos.dataTermino = "";
      campos.descricao = "";
      campos.nomeSolicitante = sessionStorage.getItem('@SECRETARIA/nomeUsuarioLogado');
    }

    this.setState({dadosAtuais: dadosAtuais ?? [], isLoading: false, Selects: campos, errors: {}});
  }

  OnChange(event) {
    var campos = this.state.Selects;
    campos[event.target.name] = event.target.value;

    if (event.target.name === "tipoBolsaNova" && event.target.value === 0) {
      campos["percentualBolsaNova"] = "0";
    }

    if (event.target.name === "percentualBolsaNova" && parseFloat(event.target.value) > 100) {
      campos[event.target.name] = 100
    }

    if (event.target.name === "percentualBolsaNova" && parseFloat(event.target.value) < 0) {
      campos[event.target.name] = 0
    }

    this.setState({Selects: campos});

    this.FormValidation();
  }

  FormValidation() {

    let errors = {};
    let errorCount = 0;
    let inputs = this.state.Selects;

    if (!formValidacao.isValidTexto(inputs.motivoAlteracao)) {
      errors["motivoAlteracao"] = "Campo obrigatório.";
      errorCount++;
    } else {
      errors["motivoAlteracao"] = "";
    }
    if (!formValidacao.isValidComboBox(inputs.tipoBolsaNova)) {
      errors["tipoBolsaNova"] = "Campo obrigatório.";
      errorCount++;
    } else {
      errors["tipoBolsaNova"] = "";
    }
    if (!formValidacao.isValidTexto(inputs.dataInicio)) {
      errors["dataInicio"] = "Campo obrigatório.";
      errorCount++;
    } else {
      errors["dataInicio"] = "";
    }
    if (!formValidacao.isValidTexto(inputs.dataTermino)) {
      errors["dataTermino"] = "Campo obrigatório.";
      errorCount++;
    } else {
      errors["dataTermino"] = "";
    }

    if (!formValidacao.isValidTexto(inputs.descricao)) {
      errors["descricao"] = "Campo obrigatório.";
      errorCount++;
    } else {
      errors["descricao"] = "";
    }

    if (!formValidacao.isValidTexto(inputs.nomeSolicitante)) {
      errors["nomeSolicitante"] = "Campo obrigatório.";
      errorCount++;
    } else {
      errors["nomeSolicitante"] = "";
    }

    this.setState({errors: errors, formIsValid: errorCount == 0});
    return errorCount == 0;
  }

  async SolicitaAltercaoBolsaAtual(event) {
    event.preventDefault();

    if (!this.FormValidation()) {
      return false;
    }

    try {
        this.setState({isLoading: true});

        var dadosAtuais = this.state.dadosAtuais;

        var percentualBolsaNova = Number(this.state.Selects.percentualBolsaNova.replace("%", ""));
        var tipoBolsaNova = Number(this.state.Selects.tipoBolsaNova);

        var request = {
            matriculaId: this.props.Matricula.idMatricula,
            motivoAlteracao: this.state.Selects.motivoAlteracao,
            tipoBolsaNova: tipoBolsaNova,
            percentualBolsaNova: parseFloat(percentualBolsaNova.toLocaleString("en-US", {
              maximumFractionDigits: 2
            })),
            dataInicio: this.state.Selects.dataInicio,
            dataTermino: this.state.Selects.dataTermino,
            descricao: this.state.Selects.descricao,
            nomeSolicitante: this.state.Selects.nomeSolicitante
        };

        var result = await _movimentacoesService.SolicitaAltercaoBolsaAtual(request);

        if (result != undefined && result != null && result.statusCode != undefined && (result.statusCode == 0 || result.statusCode == 200)) {
            Message.ExibeMensagem("Solicitação registrada com sucesso!!", '0', true);
            this.getBolsaAtual();

        } else {
            if (result?.value != undefined) {
                Message.ExibeMensagem(result.value, "2", true);
            } else if (result?.title != undefined) {
                Message.ExibeMensagem(result.title, "2", true);
            } else {
                Message.ExibeMensagem("Erro ao alterar a bolsa!", "2", true);
            }
        }

        this.setState({isLoading: false});
    } catch (e) {
        console.error(e)
    } finally {
        if (this.props.handleShowCsat) this.props.handleShowCsat(csatList.bolsas)
    }
  }

  SelecioneBolsa(bolsa) {
    var campos = this.state.Selects;
    campos["tipoBolsaNova"] = bolsa.value;
    campos["percentualBolsaNova"] = "0%";

    this.setState({Selects: campos});
    this.setState({tipoBolsa: bolsa.name});
  }

  render() {
    const {
      isLoading,
      dadosAtuais,
      Selects,
      listaTipoBolsaNova,
      motivoAlteracao,
      tipoBolsaNova,
      percentualBolsaNova,
      tipoBolsa,
      bolsaAlterada,
      permiteMovimentarAvista: permiteMovimentarAvista
    } = this.state;

    {
      if (!permiteMovimentarAvista) {
        return (
          <div>
            <div className=" form-group d-flex justify-content-center">
              <h3 style={{color: '#0068b2'}}><strong>AVISO</strong></h3>
            </div>
            <div className=" form-group d-flex justify-content-center">
              <h5 style={{color: '#0068b2'}}> O Template selecionado não permite movimentações onde o pagamento é
                Avista.</h5>
            </div>
          </div>
        );
      }
    }

    {
      if (bolsaAlterada) {
        return (
          <div>
            <div className=" form-group d-flex justify-content-center">
              <h3 style={{color: '#0068b2'}}><strong>BOLSA DA
                MATRÍCULA {this.props.Matricula?.codigoMatricula} ALTERADA! </strong></h3>
            </div>
            <div className=" form-group d-flex justify-content-center">
              <h5 style={{color: '#0068b2'}}> Importante: O desconto será aplicado na proxima mensalidade.</h5>
            </div>
          </div>
        );
      }
    }

    if (isLoading) {
      return <LoadingAnimation/>
    } else {
      return (
        <div className="row p-b-10">
          <fieldset className="p-b-10 p-t-10">
            <legend className="pull-left width-full">
              Informações da Matrícula / Alteração de Bolsa{" "}
              <PersonFill className="fa-icon iconeAzul"/>{" "}
            </legend>
            <div className="row">
              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>Matrícula</label>
                  <input
                    className="form-control"
                    disabled
                    value={this.props.Matricula?.codigoMatricula}
                  />
                </div>
              </div>
              <div className="col-md-8 p-b-10">
                <div className="form-group">
                  <label>Nome</label>
                  <input
                    className="form-control"
                    disabled
                    value={this.props.Matricula?.estudante_Name}
                  />
                </div>
              </div>
              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>Tipo da Bolsa</label>
                  <input
                    className="form-control"
                    name="tipoDaBolsa"
                    disabled
                    value={dadosAtuais.tipoDaBolsa}
                  />
                </div>
              </div>
              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>% da Bolsa</label>
                  <input
                    className="form-control"
                    name="percentualBolsa"
                    disabled
                    value={dadosAtuais.percentualBolsa + "%"}
                  />
                </div>
              </div>
            </div>
            <hr/>
            <legend className="pull-left width-full">
              {dadosAtuais.hasMovimentacaoPendente ? "Já existe solicitação de alteração para essa matricula." : "Nova alteração de bolsa"}
            </legend>
            <div className="row fundoCinza" style={{paddingBottom: "10px"}}>
              <div className="col-md-12 fundoCinza">
                <div className="form-group">
                  <label>Nome Solicitante : </label>
                  <input
                    disabled={true}
                    type="text"
                    name="nomeSolicitante"
                    onChange={(e) => this.OnChange(e)}
                    value={Selects?.nomeSolicitante}
                    className={
                      "form-control " +
                      formValidacao.cssTexto(
                        this.state.errors["nomeSolicitante"],
                        Selects?.nomeSolicitante
                      )
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row fundoCinza">
              <div className="col-md-4 p-b-10">
                <div className="form-group">
                  <label>Motivo da alteração</label>
                  <input
                    maxLength={99}
                    disabled={dadosAtuais.hasMovimentacaoPendente}
                    className={
                      "form-control " +
                      formValidacao.cssTexto(
                        this.state.errors["motivoAlteracao"],
                        Selects?.motivoAlteracao
                      )
                    }
                    name="motivoAlteracao"
                    value={Selects?.motivoAlteracao}
                    onChange={(e) => this.OnChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-2 p-b-10">
                <div className="form-group">
                  <label>Tipo da Nova Bolsa</label>
                  <Nav

                    className='me-auto'>
                    <NavDropdown className={"Fundounidade ddlFundoBranco ddlPadding0 form-control " +
                      formValidacao.cssComboBox(
                        this.state.errors["tipoBolsaNova"],
                        Selects?.tipoBolsaNova
                      )}
                                 title={(tipoBolsa != null && tipoBolsa != undefined && tipoBolsa != "" ? tipoBolsa : "Selecione a bolsa...")}
                                 id='collasible-nav-dropdown'
                                 disabled={dadosAtuais.hasMovimentacaoPendente}
                    >

                      {listaTipoBolsaNova?.map((item, idx) => (

                        <NavDropdown.Item key={idx} className="cssScroll" onClick={() => this.SelecioneBolsa(item)}
                                          href='#'>
                          {item.name}
                        </NavDropdown.Item>
                      ))}
                    </NavDropdown>
                  </Nav>

                </div>
              </div>
              <div className="col-md-2 p-b-10">
                <div className="form-group">
                  <label>% da Nova Bolsa</label>
                  <input
                    disabled={dadosAtuais.hasMovimentacaoPendente || Selects.tipoBolsaNova == "284360016"}
                    className={
                      "form-control "
                    }
                    name="percentualBolsaNova"
                    type="number"
                    min="0"
                    step="0.10"
                    max="100"
                    value={Selects?.percentualBolsaNova}
                    onChange={(e) => this.OnChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-2 p-b-10">
                <div className="form-group">
                  <label>Início da Bolsa</label>
                  <input
                    type="date"
                    disabled={dadosAtuais.hasMovimentacaoPendente}
                    className={
                      "form-control " +
                      formValidacao.cssTexto(
                        this.state.errors["dataInicio"],
                        Selects?.dataInicio
                      )
                    }
                    name="dataInicio"
                    value={Selects?.dataInicio}
                    onChange={(e) => this.OnChange(e)}
                  />
                </div>
              </div>
              <div className="col-md-2 p-b-10">
                <div className="form-group">
                  <label>Término da Bolsa</label>
                  <input
                    type="date"
                    disabled={dadosAtuais.hasMovimentacaoPendente}
                    className={
                      "form-control " +
                      formValidacao.cssTexto(
                        this.state.errors["dataTermino"],
                        Selects?.dataTermino
                      )
                    }
                    name="dataTermino"
                    value={Selects?.dataTermino}
                    onChange={(e) => this.OnChange(e)}
                  />
                </div>
              </div>

            </div>
            <div className="row fundoCinza" style={{paddingBottom: "10px"}}>
              <div className="col-md-12">
                <div className="form-group">

                  <label>Descrição da Bolsa : </label>
                  <textarea
                    disabled={dadosAtuais.hasMovimentacaoPendente || Selects.tipoBolsaNova == "284360016"}
                    type="textarea"
                    name="descricao"
                    onChange={(e) => this.OnChange(e)}
                    value={Selects?.descricao}
                    className={
                      "form-control " +
                      formValidacao.cssTexto(
                        this.state.errors["descricao"],
                        Selects?.descricao
                      )
                    }

                  />
                </div>
              </div>
            </div>
            <div className="row fundoCinza justify-content-center" style={{paddingBottom: "10px"}}>
              {!dadosAtuais.hasMovimentacaoPendente &&
                <div className="col-md-2 p-b-10">
                  <div className="form-group">
                    <Button
                      size="lg"
                      name="Altera Bolsa"
                      variant="primary"
                      style={{width: "100%"}}
                      onClick={(e) => this.SolicitaAltercaoBolsaAtual(e)}
                      disabled={!this.state.formIsValid}
                    >
                      {this.state.isLoadingConfirmacao
                        ? "Cancelando…"
                        : "Confirmar"}
                    </Button>
                  </div>
                </div>
              }


              <br/>
            </div>
            <hr/>
          </fieldset>
        </div>
      );
    }


  }
}

export default withUnidadeMatricula(Bolsas, "Bolsas", csatList.bolsas);
