import React, { useState } from 'react';
import { Badge, Button, Spinner, Tab, Tabs } from "react-bootstrap";
import {
  CreateUpdateFicha,
  DeleteFicha,
  GetFichaFinanceiraByIdMatricula,
} from "../../service/manutencao-financeira-service";
import ReactTooltip from "react-tooltip";
import {
  CancelTwoTone,
  ContentCopyOutlined,
  DeleteTwoTone,
  EditTwoTone,
  SaveTwoTone
} from "@mui/icons-material";
import CustomTable from "../../components/CustomTable/index";
import { AuthContext } from "../../context/AuthContext";
import Swal from "sweetalert2";
import withUnidadeMatricula from "../../hocs/withUnidadeMatricula";

function ManutencaoFinanceira(
  {
    Matricula,
  }
) {
  const [isLoading, setIsLoading] = useState(false);
  const [rows, setRows] = useState(null);
  const [search, setSearch] = useState("");
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [deletingRows, setDeletingRows] = useState([]);
  const [editingRows, setEditingRows] = useState([]);
  const [editedRows, setEditedRows] = useState([]);
  const [selectedRows, setSelectedRows] = useState([]);

  const tableConfigs = {
    selection: true,
    editing: editingRows,
    actions: [
      (row) => {
        if (editingRows.includes(row.IdFicha)) {
          return {
            id: "cancel-edit",
            label: <><CancelTwoTone /> Cancelar edição</>,
          }
        } else {
          return {
            id: "edit",
            label: <><EditTwoTone /> Habilitar edição</>,
          }
        }
      },
      {
        id: "copy",
        label: <><ContentCopyOutlined /> Duplicar esta linha logo abaixo</>,
      }
    ],
    onClickAction: (action, row) => {
      const event = new Event("click");
      switch (action.id) {
        case "edit":
          addEditing(row);
          break;
        case "cancel-edit":
          setEditingRows([...editingRows.filter((rid) => rid !== row.id)]);
          break;
        case "copy":
          return handleCopyRow(row)(event);
        default:
          console.warn("Unhandled option: ", action);
          break;
      }
    },
  }

  React.useEffect(() => {
    if (isLoading || rows !== null) return;

    setIsLoading(true);
    const getFicha = async () => {
      const fichaResp = await GetFichaFinanceiraByIdMatricula(Matricula.idMatricula) || [];

      setRows([...fichaResp.map(row => {
        return {
          ...row,
          id: row.idFicha
        }
      })]);
    }

    getFicha().then(() => setIsLoading(false));
  }, [isLoading, Matricula.idMatricula, rows]);

  const handleCopyRow = row => event => {
    const index = rows.indexOf(row);
    const newRow = { ...row };

    newRow.id = `${newRow.id}-copy`;

    insertRowAtPosition(index + 1, newRow);
    addEditing(newRow);
    setEditedRows([
      ...editedRows.filter((r) => r.id !== newRow.id),
      newRow,
    ])
  }

  const addEditing = (row) => {
    setEditingRows([...editingRows.filter(rid => rid !== row.id), row.id]);
    setDeletingRows([...deletingRows.filter(rid => rid !== row.id)]);
    setSelectedRows([...selectedRows.filter(rid => rid !== row.id), row.id]);
  }

  const insertRowAtPosition = (position, row) => {
    const newRows = [...rows];

    newRows.splice(position, 0, { ...row });

    setRows(newRows);
  };

  const handleSave = async () => {
    setIsSaving(true);

    const rowsToCreate = (editedRows || []).filter(row => String(row.id).includes("-copy"));
    const rowsToUpdate = (editedRows || []).filter(row => !String(row.id).includes("-copy"));

    await CreateUpdateFicha(Matricula.idMatricula, rowsToCreate, rowsToUpdate);

    setIsSaving(false);
    resetTableState();
    setRows(null);
  }

  const handleDelete = () => {
    Swal.fire({
      title: 'Tem certeza que deseja apagar esta(s) linha(s)?',
      text: "Esta ação não poderá ser desfeita!",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      cancelButtonText: 'Cancelar',
      confirmButtonText: 'Sim, apagar!',
      showLoaderOnConfirm: true,
      allowOutsideClick: () => !Swal.isLoading(),
      preConfirm: async () => {
        setIsDeleting(true);

        const rowsToDelete = rows.filter(row => (deletingRows || []).includes(row.id));
        await DeleteFicha(Matricula.idMatricula, rowsToDelete);

        setIsDeleting(false);
        resetTableState();
        setRows(null);

        return true;
      }
    }).then((result) => {
      if (result.isConfirmed) {
        Swal.fire(
          'Apagado!',
          'As linhas selecionadas foram apagadas.',
          'success'
        )
      }
    })
  }

  const handleChangeRow = (rowId, field, value) => {
    const row = editedRows.find(row => row.id === rowId) || rows.find(row => row.id === rowId);;
    if (row) {
      const newRow = { ...row };
      newRow[field] = value;
      setEditedRows([
        ...editedRows.filter((r) => r.id !== rowId),
        newRow,
      ])
    }
  }

  const replaceForEditedRows = (row) => {
    const editedRow = editedRows.find((r) => r.id === row.id);
    if (editedRow) {
      return editedRow;
    }
    return row;
  }

  const filterBySearch = (row) => {
    if (!search) return true;

    const rowValues = Object.values(row);
    const searchValues = rowValues.filter(value => String(value).toLowerCase().includes(search.toLowerCase().trim()));

    return searchValues.length > 0;
  }

  if (isLoading) {
    return (
      <div>
        Carregando...
      </div>
    )
  }

  const renderTab = (tableColumns, rows) => {
    return (
      <>
        <div className="filtros">
          <div className="row mb-3">
            <div className="col-md-3">
              <div className="form-group">
                <label>Pesquisar</label>
                <input
                  className="form-control"
                  name="search"
                  value={search}
                  onChange={event => setSearch(event.target.value)}
                />
              </div>
            </div>

            <div className="col-md-9">
              <div className="row justify-content-end m-r-1">
                <Button
                  type="button"
                  variant="primary"
                  disabled={isSaving || !editedRows.length}
                  style={{ marginTop: 15, marginRight: 15 }}
                  onClick={handleSave}
                  data-tip={`Salvar ${editedRows.length} linha(s) editada(s)`}
                >
                  {isSaving ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                    >
                      <span className="visually-hidden">Salvando...</span>
                    </Spinner>
                  ) : (
                    <>
                      <SaveTwoTone />
                      {!!editedRows.length && <Badge bg="secondary">{editedRows.length}</Badge>}
                    </>
                  )}
                </Button>

                <Button
                  type="button"
                  variant="danger"
                  disabled={deletingRows.length === 0 || isDeleting || isSaving}
                  style={{ marginTop: 15 }}
                  onClick={handleDelete}
                  data-tip={`Remover ${deletingRows.length} linha(s) selecionada(s)`}
                >
                  {isDeleting ? (
                    <Spinner
                      animation="border"
                      role="status"
                      size="sm"
                    >
                      <span className="visually-hidden">Removendo linhas...</span>
                    </Spinner>
                  ) : (
                    <>
                      <DeleteTwoTone />
                      {!!deletingRows.length && <Badge bg="secondary">{deletingRows.length}</Badge>}
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>

        <CustomTable
          columns={tableColumns}
          data={(rows || []).map(replaceForEditedRows).filter(filterBySearch)}
          configs={tableConfigs}
          onEditRow={addEditing}
          selected={selectedRows}
          onChangeRow={handleChangeRow}
          onSelectionChange={(selection = []) => {
            setSelectedRows(selection);
            setDeletingRows(selection.filter((rid) => !editingRows.includes(rid)));
          }}
        />
      </>
    )
  }

  const resetTableState = (eventKey) => {
    setDeletingRows([]);
    setEditingRows([]);
    setSelectedRows([]);
    setEditedRows([]);
  }

  return (
    <AuthContext.Consumer>
      {({ appContext }) => {
        const { tables } = appContext || {};
        const fichaETitulosTableColumns = tables["mf-fichas-titulos"]?.columns || [];
        // const ovsTableColumns = tables["mf-ovs"]?.columns || [];

        return (
          <div className="seb-secretaria-component-modal-financeiro">
            <ReactTooltip
              place="top"
              type="dark"
              effect="float"
              className="tooltip"
            />

            <Tabs
              defaultActiveKey="ficha"
              className="mb-3"
              onSelect={resetTableState}
            >
              <Tab eventKey="ficha" title="Fichas e títulos">
                {renderTab(fichaETitulosTableColumns, rows)}
              </Tab>
              {/*<Tab eventKey="ov" title="OVs">*/}
              {/*  {renderTab(ovsTableColumns, rows)}*/}
              {/*</Tab>*/}
            </Tabs>
          </div>
        )
      }}
    </AuthContext.Consumer>
  );
}

export default withUnidadeMatricula(ManutencaoFinanceira, "Manutenção Financeira");