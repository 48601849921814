import {atom} from "recoil";
import {persistAtomEffect} from "../effects/persistAtom";
import {ContatoColaborador} from "seb-graph-api-types/generated";

export const selectedColaboradorState = atom<ContatoColaborador | null>({
  key: 'selectedColaboradorState',
  default: null,
  effects: [
    persistAtomEffect
  ]
});
