import {onError} from "@apollo/client/link/error";
import {ApolloClient, FetchResult, from, GraphQLRequest, InMemoryCache, makeVar, Observable} from "@apollo/client";
import {appConfig, graphApiConfig} from "../config";
import {BatchHttpLink} from "@apollo/client/link/batch-http";
import {setContext} from "@apollo/client/link/context";
import {GraphQLError} from "graphql/error";
import MUTATE_REFRESH_TOKEN from "../graphql/mutations/refresh-token";
import errorCodes from "seb-graph-api-types/errorCodes";

export const STORAGE_TOKEN_KEY = 'sebAccessToken';
export const STORAGE_REFRESH_TOKEN_KEY = 'sebRefreshToken';

export const needRefreshToken = makeVar(false);

const create = () => {
  function isRefreshRequest(operation: GraphQLRequest) {
    return operation.operationName === 'refreshToken';
  }

  function returnTokenDependingOnOperation(operation: GraphQLRequest) {
    if (isRefreshRequest(operation))
      return localStorage.getItem(STORAGE_REFRESH_TOKEN_KEY) || '';
    else return localStorage.getItem(STORAGE_TOKEN_KEY) || '';
  }

  const authLink = setContext((operation, { headers }) => {
    let token = returnTokenDependingOnOperation(operation);
    return {
      headers: {
        ...headers,
        authorization: token ? `Bearer ${token}` : '',
        'Seb-System-Id': appConfig.appId
      },
    };
  });

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors) {
        for (let err of graphQLErrors) {
          switch (err.extensions.code) {
            case errorCodes.SESSION_EXPIRED_ERROR:
              if (operation.operationName === 'refreshToken') return;

              const observable = new Observable<FetchResult<Record<string, any>>>(
                (observer) => {
                  (async () => {
                    try {
                      const accessToken = await refreshToken();

                      if (!accessToken) {
                        throw new GraphQLError('Empty AccessToken');
                      }

                      // Retry the failed request
                      const subscriber = {
                        next: observer.next.bind(observer),
                        error: observer.error.bind(observer),
                        complete: observer.complete.bind(observer),
                      };

                      forward(operation).subscribe(subscriber);
                    } catch (err) {
                      observer.error(err);
                    }
                  })();
                }
              );

              return observable;
          }
        }
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    }
  );

  const httpLink = new BatchHttpLink({
    uri: graphApiConfig.uri,
    batchMax: 5, // No more than 5 operations per batch
    batchInterval: 100, // Wait no more than 20ms after first batched operation
  })

  const client =  new ApolloClient({
    ssrMode: false,
    link: from([errorLink, authLink, httpLink]),
    cache: new InMemoryCache({
      addTypename: false,
      resultCacheMaxSize: 0,
      resultCaching: false,
      canonizeResults: false,
    }),
    queryDeduplication: true,
    defaultOptions: {
      watchQuery: {
        initialFetchPolicy: 'no-cache',
        fetchPolicy: 'no-cache',
        refetchWritePolicy: 'overwrite',
        nextFetchPolicy: 'no-cache',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'all',
      },
    },
    assumeImmutableResults: false,
  });

  const refreshToken = async () => {
    try {
      const refreshResolverResponse = await client.mutate<{
        refreshToken: any
      }>({
        mutation: MUTATE_REFRESH_TOKEN,
      });

      const accessToken = refreshResolverResponse.data?.refreshToken.accessToken;
      const refreshToken = refreshResolverResponse.data?.refreshToken.refreshToken;
      localStorage.setItem(STORAGE_TOKEN_KEY, accessToken || '');
      localStorage.setItem(STORAGE_REFRESH_TOKEN_KEY, refreshToken || '');
      return accessToken;
    } catch (err) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.href = '/';
    }
  };

  return client;
}

export default create
