import { toast } from 'react-toastify'
import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';

const template = (titulo, texto, link, textoLink, tipo) => {
    return (
        <>
            {titulo != undefined && titulo != null && titulo != "" &&
                <div className="row">
                <div className={"col-md-12 toastMsg "+tipo}>
                    <h5><b>{titulo}</b></h5>
                    </div>
                </div>
            }
            <div className="row">
                <div className="col-md-1">
                </div>
                <div className="col-md-11">
                    {texto}
                </div>
            </div>
            {link != undefined && link != null && link != "" &&
                <>
                <br />
                <div className="row">
                    <div className="col-md-1">
                    </div>
                <div className="col-md-11">
                        <a href="#" onClick={event => onClickToastLink(link)}>{textoLink}</a>
                    </div>
                </div>
                </>
            }
        </>
    )
}

function onClickToastLink(link) {
    if (link.toString().indexOf("http://") != -1 || link.toString().indexOf("https://") != -1) {
        window.open(link, "_blank");
    } else {
        window.location.href = link;
    }
}

export function ExibeListMensagem(menssagens, autoClose = true, titulo = "") {
    
    if (menssagens != null && menssagens != undefined && menssagens.length > 0) {
        menssagens.map(mensagem => {
            ExibeMensagem(mensagem.text, mensagem.typeMessage.toString(), autoClose, titulo);
        })
    }
}



export function ExibeMensagem(texto, tipo, autoClose = true, titulo = "", urlLink = "", textoLink = "") {
    
    toast.configure();
    var timeAutoClose = false;

    if (autoClose == true) {
        timeAutoClose = 10000;
    }
    

    if ((titulo != undefined && titulo != null && titulo != "") ||
        (urlLink != undefined && urlLink != null && urlLink != "")) {

        if (textoLink == undefined || textoLink == null || textoLink == "") {
            textoLink = "Clique Aqui";
        }

        var content = "";

        switch (tipo) {
            case '0' || 'success':
                content = template(titulo, texto, urlLink, textoLink,'toastMsgSuccess');
                break;
            case '1' || 'warning':
                content = template(titulo, texto, urlLink, textoLink, 'toastMsgWarning');
                break;
            case '2' || 'error':
                content = template(titulo, texto, urlLink, textoLink, 'toastMsgError');
                break;
            default:
                content = template(titulo, texto, urlLink, textoLink, 'toastMsgInfo');
                break;
        }

        toast(content, { position: toast.POSITION.TOP_RIGHT, autoClose: timeAutoClose });
        
    } else {

        switch (tipo) {
            case '0' || 'success':
                toast.success(texto, { position: toast.POSITION.TOP_RIGHT, autoClose: timeAutoClose });
                break;
            case '1' || 'warning':
                toast.warning(texto, { position: toast.POSITION.TOP_RIGHT, autoClose: timeAutoClose });
                break;
            case '2' || 'error':
                toast.error(texto, { position: toast.POSITION.TOP_RIGHT, autoClose: timeAutoClose });
                break;
            default:
                toast.info(texto, { position: toast.POSITION.TOP_RIGHT, autoClose: timeAutoClose });
                break;
        }
    }
    
}


