import { forwardRef } from 'react';
import {Link} from "react-router-dom";

/**
 * This is a wrapper over `next/link` component.
 * We use this to help us maintain consistency between Vite and Next.js
 */
export const RouterLink = forwardRef(function RouterLink(props, ref) {
  const {
    href,
    ...rest
  } = props;
  return (
    <Link
      ref={ref}
      to={href}
      {...rest}
    />
  );
});
