import FinancialTransDetail from "./FinancialTransDetail";
import FrankJsonDownload from "./FrankJsonDownload";

export default class Transacao extends FrankJsonDownload {
  DataAreaId = "";
  FiscalEstablishmentId = "";
  MatriculaId = "";
  financialTransDetail = new FinancialTransDetail();

  /** @param {Transaction[]} transactions */
  addTransactions(transactions) {
    if (Array.isArray(transactions))  {
      const firstTransaction = transactions[0];

      if ("dataAreaId" in firstTransaction) {
        this.DataAreaId = firstTransaction.dataAreaId;
      }

      if ("fiscalEstablishmentId" in firstTransaction) {
        this.FiscalEstablishmentId = firstTransaction.fiscalEstablishmentId;
      }

      if ("matriculaId" in firstTransaction) {
        this.MatriculaId = firstTransaction.matriculaId;
      }

      if ('financialMovType' in firstTransaction) {
        this.financialTransDetail.financialMovType = firstTransaction.financialMovType;
      }

      if ('custAccount' in firstTransaction) {
        this.financialTransDetail.custAccount = firstTransaction.custAccount;
      }

      if ('transStatus' in firstTransaction) {
        this.financialTransDetail.transStatus = firstTransaction.transStatus;
      }

      transactions.forEach((t) => {
        this.financialTransDetail.transactions.addTransaction(t);
      });
    }
  }
}